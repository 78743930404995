import React from 'react';
import UserLayout from '../../components/Layout/UserLayout';
import AddUser from './addUser';

const title = 'New User Registration';

export default async function action({ store }) {
  
  let isAuthenticated = store.getState().runtime.isAuthenticated;
  
  if (!isAuthenticated) {
    return { redirect: '/login' };
  }
  
  return {
    title,
    component: <UserLayout><AddUser title={title} /></UserLayout>,
  };
};
