exports = module.exports = require("../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".CreateWishList-marginLeft-OGhQG {\n\tmargin-left: 12px;\n}\n\n.CreateWishList-plusIconSection-fIyey {\n\tdisplay: -webkit-box;\n\tdisplay: -ms-flexbox;\n\tdisplay: flex;\n    width: 54px;\n    height: 54px;\n    border: 1px solid #e9e9e9;\n    border-radius: 6px;\n    -webkit-box-align: center;\n        -ms-flex-align: center;\n            align-items: center;\n    -webkit-box-pack: center;\n        -ms-flex-pack: center;\n            justify-content: center;\n    padding: 13px;\n}\n\n.CreateWishList-plusIcon-dGkf1 {\n\twidth: 100%;\n    max-width: 24px;\n    height: 24px;\n    min-height: 24px;\n}\n\n.CreateWishList-createTextMargin-3hV1q {\n\tmargin: 0px 18px;\n}\n\n.CreateWishList-btnPadding-IsB2d{\n    padding: 8px 15px !important;\n    font-size: 16px !important;\n}", ""]);

// exports
exports.locals = {
	"marginLeft": "CreateWishList-marginLeft-OGhQG",
	"plusIconSection": "CreateWishList-plusIconSection-fIyey",
	"plusIcon": "CreateWishList-plusIcon-dGkf1",
	"createTextMargin": "CreateWishList-createTextMargin-3hV1q",
	"btnPadding": "CreateWishList-btnPadding-IsB2d"
};