import React from 'react';
import PropTypes from 'prop-types';
import Layout from '../layouts/Layout';
import Header from '../modules/Header';
import Body from '../modules/Body';
import Footer from '../modules/Footer';
import EmptySpace from '../modules/EmptySpace';
import { url } from '../../../config';
import { COMMON_TEXT_COLOR } from '../../../constants/index';
import { injectIntl, FormattedMessage} from 'react-intl';

class BookingPreApproval extends React.Component {

    static propTypes = {
        content: PropTypes.shape({
            guestName: PropTypes.string.isRequired,
            hostName: PropTypes.string.isRequired,
            threadId: PropTypes.number.isRequired,
            listTitle: PropTypes.number.isRequired,
            siteName: PropTypes.string.isRequired,
        })
    };

    render() {

        const linkText = {
            color: COMMON_COLOR,
            fontSize: '16px',
            textDecoration: 'none',
            cursor: 'pointer',
        }

        const textStyle = {
            color: COMMON_TEXT_COLOR,
            backgroundColor: '#F7F7F7',
            fontFamily: 'Arial',
            fontSize: '16px',
            padding: '35px'
        };
        const { content: { guestName, hostName, threadId, listTitle, logo, siteName } } = this.props;
        let contactURL = url + '/message/' + threadId + '/renter';

        return (
            <Layout>
                <Header 
                    color="rgb(255, 90, 95)" 
                    backgroundColor="#F7F7F7" 
                    logo={logo}
                    siteName={siteName}
                />
                <Body textStyle={textStyle}>
                    <div>
                        <FormattedMessage id="confirmEmailGreeting"  values={{ name: guestName }} />
                    </div>
                    <EmptySpace height={20} />
                    <div>
                        <FormattedMessage  id="booking_pre_approval_pre_approved_message"
                            values={{ hostName, listTitle, bookLink: <a style={linkText} href={contactURL}><FormattedMessage id="booking_pre_approval_book_link" defaultMessage="book" /></a> }}
                        />
                    </div>
                    <EmptySpace height={20} />
                    <div>
                        <FormattedMessage id="confirmEmailThanksMessage" /><br/>
                        <FormattedMessage id="confirmEmailTeamMessage" values={{ siteName }} />
                    </div>
                </Body>
                <Footer siteName={siteName}/>
                <EmptySpace height={20} />
            </Layout>
        );
    }

}

export default injectIntl(BookingPreApproval);