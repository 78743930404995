import React, { Component } from 'react';
import { connect } from 'react-redux';
import { injectIntl, FormattedMessage } from 'react-intl';
import DropzoneComponent from 'react-dropzone-component';
import { Col } from 'react-bootstrap';
import { toastr } from 'react-redux-toastr';
import * as XLSX from 'xlsx';

// Redux Form
import { reduxForm } from 'redux-form';

// Style
import cx from 'classnames';
import cs from '../commonStyle.css';
import withStyles from 'isomorphic-style-loader/lib/withStyles';
import s from '!isomorphic-style-loader!css-loader!./filepicker.css';

// Component
import CommonTable from '../CommonTable';
import Loader from '../Loader';

//compose
import { compose } from 'react-apollo';
//Image
import PictureImage from '/public/SiteIcons/photoUpload.svg';

// Locale
import messages from '../../locale/messages';

import submit from './submit';
import update from './update';
import updateStep2 from './updateStep2';
import updateStep3 from './updateStep3';

class HostAllCar extends Component {

  static propTypes = {
  };

  constructor(props) {
    super(props);
    this.state = {
      excelData: [],
      displayData : [],
    };
    this.addedfile = this.addedfile.bind(this);
    this.complete = this.complete.bind(this);
    this.submitForm = this.submitForm.bind(this);
    this.dropzone = null;
  }

  componentDidMount() {
    const isBrowser = typeof window !== 'undefined';
    const isDocument = typeof document !== undefined;
    if (isBrowser && isDocument) {
      document.querySelector(".dz-hidden-input").style.visibility = 'visible';
      document.querySelector(".dz-hidden-input").style.opacity = '0';
      document.querySelector(".dz-hidden-input").style.height = '100%';
      document.querySelector(".dz-hidden-input").style.width = '100%';
      document.querySelector(".dz-hidden-input").style.cursor = 'pointer';
    }
  }

  convertJsonData = (jsonData) => {
    if (!jsonData || jsonData.length === 0) {
      return [];
    }
    
    const columns = jsonData[0];
    const rows = [];
    
    for (let i = 1; i < jsonData.length; i++) {
      const row = {};
      for (let j = 0; j < columns.length; j++) {
        const columnName = columns[j];
        let cellValue = jsonData[i][j];
        if (columnName === 'carFeatures' || columnName === 'carRules') {
          cellValue = typeof cellValue === 'string' ? cellValue.split(",").map(Number) : null;
        }
        row[columnName] = cellValue !== null ? cellValue : null;
      }
      rows.push(row);
    }
    
    return rows;
  };

  async submitForm() {
    const { dispatch } = this.props;
    const { excelData } = this.state;
    console.log("dispatch :", dispatch);
    if (!excelData || excelData.length === 0) {
      return [];
    }
    const convertedData = this.convertJsonData(excelData);
    console.log("convertedData :", convertedData);
    for (let i = 0; i < convertedData.length; i++) {
      const id = await submit(convertedData[i],dispatch);
      convertedData[i]['id'] = id ;
      await update(convertedData[i],dispatch);
      await updateStep2(convertedData[i],dispatch);
      await updateStep3(convertedData[i],dispatch);
      convertedData[i]['photo'] = '/become-a-owner/' + btoa(id) + '/photos';
    }
    toastr.success('Success!', 'Your successfully Save Cars!');
    this.setState({ displayData: convertedData });

  }

  async complete(file) {      
      if (file.type === 'application/vnd.ms-excel' || file.type === 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet') 
      {
        const reader = new FileReader();
            reader.onload = async (e) => {
              try {
                const data = new Uint8Array(e.target.result);
                const workbook = XLSX.read(data, { type: 'array' });
                const sheetName = workbook.SheetNames[0];
                const sheet = workbook.Sheets[sheetName];
                const jsonData = XLSX.utils.sheet_to_json(sheet, { header: 1 });
                this.setState({ excelData: jsonData });
                toastr.success('Success!', 'Your successfully upload!');
              } catch (error) {
                toastr.error('Error!', 'Failed to read Excel file.');
              }
            };
            reader.readAsArrayBuffer(file);
        }
  }

  async addedfile(file) {
    const { maxUploadSize } = this.props;
  
    const filetypes = ['application/vnd.ms-excel', 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'];
  
    if (file.size > (1024 * 1024 * parseInt(maxUploadSize))) {
      this.dropzone.removeFile(file);
      toastr.error('Maximum upload size exceeded!', 'Try with a smaller size image');
      return;
    }

    if (!filetypes.includes(file.type)) {
      toastr.error('Error!', 'You are trying to upload an invalid file. Please upload XLS, or XLSX format files.');
      this.dropzone.removeFile(file);
      return;
    }    
  }

  thead = () => {
    const { formatMessage } = this.props.intl;
    return [
      { data: formatMessage(messages.carNameLabel) },
      { data: formatMessage(messages.aboutCar) },
      { data: formatMessage(messages.address) },
      { data: formatMessage(messages.photos) },
    ]
  };
  
  tbody = () => {
    const { displayData } = this.state;
    return displayData?.map(value => {
      return {
        id: value?.id,
        data: [          
          { data: value.title },
          { data: value.description },
          { data: value.street + ', ' + value.city + ', ' + value.state + ', ' + value.country + ', ' + value.zipcode },
          {
            data: <>
                  <a target="_blank" href={value?.photo} className={cx(s.modalCaptionLink, 'modalCaptionLinkStepRTL')}>
                    <FormattedMessage {...messages.photos} />
                  </a>
                </>
          },
        ]
    }});
  };

  render() {   
    const { placeholder, handleSubmit } = this.props;
		const { formatMessage } = this.props.intl;
    const djsConfig = {
      dictDefaultMessage: '',
      addRemoveLinks: false,
      maxFilesize: 10,
      maxFiles: 10,
      acceptedFiles: 'application/vnd.ms-excel,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
      hiddenInputContainer: '.dzInputContainer'
    };
    const componentConfig = {
      iconFiletypes: ['.xls', '.xlsx'],
      postUrl: '/documents'
    };
    const eventHandlers = {
      init: dz => this.dropzone = dz,
      complete: this.complete,
      addedfile: this.addedfile
    };

    return (
      <>
      <Col xs={12} sm={12} md={12} lg={12} className={cs.spaceTop6}>
        <div className={cx(cs.spaceTop6, 'whiteBgColor', 'youcarsBg')}>
          <h3 className={cx(cs.commonSubTitleText, cs.fontWeightBold, cs.paddingBottom3, cs.textAlignCenter)}>
              <FormattedMessage {...messages.listingsManagement} />
          </h3>
          <form onSubmit={handleSubmit(this.submitForm)}>
            <div className={cx('listPhotoContainer')}>
              <div className={cx('dzInputContainer')}>
                <DropzoneComponent
                  config={componentConfig}
                  eventHandlers={eventHandlers}
                  djsConfig={djsConfig}
                >
                  <img src={PictureImage} className={'photoUploadImg'} alt='PictureImage' />
                  <span className={cx('documentPlaceholder')}>{placeholder}</span>
                </DropzoneComponent>
              </div>
            </div>
              <div className={cx(cs.textAlignRight, 'textAlignLeftRTL')}>
                  <Loader
                    type={"button"}
                    buttonType={"button"}
                    className={cx(cs.btnPrimary, cs.btnBig, cs.displayinlineBlock)}
                    label={formatMessage(messages.save)}
                    handleClick={this.submitForm}
                  />
              </div>
          </form>
          <CommonTable
            thead={this.thead}
            tbody={this.tbody}
          />
        </div>
      </Col>
    </>
    );
  }
}

const mapState = (state) => ({
  maxUploadSize: state.siteSettings.data.maxUploadSize
});

HostAllCar = reduxForm({
  form: 'HostAllCar'
})(HostAllCar);

const mapDispatch = {
};

export default compose(injectIntl, withStyles(s, cs), (connect(mapState, mapDispatch)))(HostAllCar);