import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Table, TBody, TR, TD } from 'oy-vey';
import Layout from '../layouts/Layout';
import Header from '../modules/Header';
import Body from '../modules/Body';
import Footer from '../modules/Footer';
import EmptySpace from '../modules/EmptySpace';
import { url } from '../../../config';
import { COMMON_COLOR, COMMON_TEXT_COLOR } from '../../../constants/index';
import { injectIntl, FormattedMessage } from 'react-intl';

class BookingConfirmationGuest extends Component {
	static propTypes = {
		content: PropTypes.shape({
			hostName: PropTypes.string.isRequired,
			guestName: PropTypes.string.isRequired,
			listTitle: PropTypes.string.isRequired,
			listCity: PropTypes.string.isRequired,
			threadId: PropTypes.number.isRequired,
			siteName: PropTypes.string.isRequired,
		}).isRequired
	};

	render() {
		const textStyle = {
			color: COMMON_TEXT_COLOR,
			backgroundColor: '#F7F7F7',
			fontFamily: 'Arial',
			fontSize: '16px',
			padding: '35px',
		};

		const linkText = {
			color: COMMON_COLOR,
			fontSize: '16px',
			textDecoration: 'none',
			cursor: 'pointer',
		}


		const { content: { guestName, hostName, listTitle, listCity, threadId, logo, siteName } } = this.props;
		let contactURL = url + '/message/' + threadId + '/renter';

		return (
			<Layout>
				<Header
					color="rgb(255, 90, 95)"
					backgroundColor="#F7F7F7"
					logo={logo}
					siteName={siteName}
				/>
				<div>
					<Table width="100%" >
						<TBody>
							<TR>
								<TD style={textStyle}>
									<EmptySpace height={20} />
									<div>
                    					<FormattedMessage id="confirmEmailGreeting"  values={{ name: hostName }} />
									</div>
									<EmptySpace height={20} />
									<div>
										<FormattedMessage id="booking_confirmation_guest_trip_message" />
									</div>
									<EmptySpace height={20} />
									<div>
										<FormattedMessage
											id="booking_confirmation_guest_confirmation_message"
											values={{ hostName, contactOwnerLink: <a style={linkText} href={contactURL}>contact owner</a> }}
										/>
									</div>
									<EmptySpace height={20} />
									<div>
										<FormattedMessage id="confirmEmailThanksMessage" /><br/>
										<FormattedMessage id="confirmEmailTeamMessage" values={{ siteName }} />
									</div>
								</TD>
							</TR>
						</TBody>
					</Table>
					<EmptySpace height={40} />
				</div>
				<Footer  siteName={siteName}/>
				<EmptySpace height={20} />
			</Layout>
		);
	}
}

export default injectIntl(BookingConfirmationGuest);
