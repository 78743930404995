exports = module.exports = require("../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".ClaimPayoutModal-errorMessage-2oMV5 {\n\tcolor: red;\n}\n.ClaimPayoutModal-widthCss-hEoYz{\n\tmax-width: 380px;\n    width: 100%;\n    margin: 0 auto;\n}\n.ClaimPayoutModal-fullWidth-1eo-h{\n\twidth: 100%;\n}\n@media screen and (max-width: 1200px) and (min-width: 768px) {\n    .ClaimPayoutModal-widthCss-hEoYz{\n\t\tmax-width: 100%;\n\t}\n\t.ClaimPayoutModal-disPlayInline-fT1Ep{\n\t\twidth: 50%;\n\t\tmargin: 18px auto 0;\n\t\n\t}\n}\n@media screen and (max-width: 767px) {\n\t.ClaimPayoutModal-widthCss-hEoYz{\n\t\tmax-width: 100%;\n\t}\n}", ""]);

// exports
exports.locals = {
	"errorMessage": "ClaimPayoutModal-errorMessage-2oMV5",
	"widthCss": "ClaimPayoutModal-widthCss-hEoYz",
	"fullWidth": "ClaimPayoutModal-fullWidth-1eo-h",
	"disPlayInline": "ClaimPayoutModal-disPlayInline-fT1Ep"
};