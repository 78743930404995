import { gql } from 'react-apollo';
import { initialize, change } from 'redux-form';
import {
  GET_CAR_CALENDAR_DATA_START,
  GET_CAR_CALENDAR_DATA_SUCCESS,
  GET_CAR_CALENDAR_DATA_ERROR,
  RESET_CAR_CALENDAR_DATA
} from '../constants';

const query = gql`
  query ($listId:String!) {
    UserListing (listId:$listId) {
      id
      userId
      bookingType
      isPublished
      houseRules {
        houseRulesId
      }
      listingData {
        bookingNoticeTime,
        checkInStart,
        checkInEnd,
        maxDaysNotice,
        minDay,
        maxDay,
        basePrice,
        delivery,
        currency,
        weeklyDiscount,
        monthlyDiscount,
        cancellationPolicy
        securityDeposit
      }
      blockedDates {
        blockedDates
        reservationId
        calendarStatus
        isSpecialPrice
      }
      calendars {
        id
        name
        url
        listId
        status
      }
      listBlockedPrice {
          listId
          calendarStatus
          isSpecialPrice
          blockedDates
          calendarId
          reservationId
      }
    }
  }
`;

export function getCarCalendarData(listId) {
  return async (dispatch, getState, { client }) => {
    dispatch({
      type: GET_CAR_CALENDAR_DATA_START,
    });

    try {
      let formValues = null;
      let settingFieldsData = {};
      const carRules = [];
      const updatedBlockedDates = [];
      const updatedDisabledDates = [];
      const updatedAvailableDates = [];
      const updatedAvailableDatesPrices = [];

      let listData = {};
      let calendars = {};
      let listAvailablePrice = {};

      // Send Request to get listing data
      const { data } = await client.query({
        query,
        variables: { listId },
        fetchPolicy: 'network-only',
      });

      if (data && data.UserListing) {
        // Preparing List data
        listData = data.UserListing.listingData;
        calendars = data.UserListing.calendars;
        listAvailablePrice = data && data.UserListing.listAvailablePrice;

        // Preparing for house rules
        if (data.UserListing.houseRules.length > 0) {
          data.UserListing.houseRules.map((item, value) => {
            carRules.push(parseInt(item.houseRulesId));
          });
          settingFieldsData = Object.assign({}, listData, { carRules });
        }

        // Preparing for blocked dates
        if (data.UserListing.blockedDates.length > 0) {
          await data.UserListing.blockedDates.map((item, value) => {
            if (item.reservationId) {
              updatedDisabledDates.push(new Date(item.blockedDates));
            } if (item.calendarStatus && item.calendarStatus === 'available') {
              updatedAvailableDates.push(new Date(item.blockedDates));
              updatedAvailableDatesPrices.push({
                date: new Date(item.blockedDates),
                isSpecialPrice: item.isSpecialPrice
              });
            } else {
              updatedBlockedDates.push(new Date(item.blockedDates));
            }

          });
          settingFieldsData = Object.assign({}, listData, settingFieldsData,
            {
              disabledDates: updatedDisabledDates,
              blockedDates: updatedBlockedDates,
              availableDates: updatedAvailableDates,
              availableDatesPrices: updatedAvailableDatesPrices,
            });
        }

        await dispatch(change('CarCalendar', 'calendars', calendars));
        if (updatedBlockedDates) {
          await dispatch(change('CarCalendar', 'blockedDates', updatedBlockedDates));
        } else if (updatedAvailableDates) {
          await dispatch(change('CarCalendar', 'blockedDates', updatedAvailableDates));
        } else if (updatedAvailableDatesPrices) {
          await dispatch(change('CarCalendar', 'blockedDates', updatedAvailableDatesPrices));
        }

        formValues = Object.assign({}, data.UserListing, settingFieldsData, listData, calendars);

        // Reinitialize the form values
        await dispatch(initialize('CarCalendar', formValues));
        dispatch({
          type: GET_CAR_CALENDAR_DATA_SUCCESS,
          isExistingList: true,
          calendars: data.UserListing.calendars
        });
      } else {
        dispatch({
          type: GET_CAR_CALENDAR_DATA_ERROR,
        });
      }
    } catch (error) {
      dispatch({
        type: GET_CAR_CALENDAR_DATA_ERROR,
        payload: {
          error,
        },
      });
      return false;
    }
    return true;
  };
}

export function resetCarCalendarData() {
  return async (dispatch) => {
    try {
      dispatch(initialize('CarCalendar', {}));
      
      dispatch({
        type: RESET_CAR_CALENDAR_DATA,
        calendars: undefined,
        isExistingList: undefined,
      });

    } catch (error) {
      console.error("Error resetting car calendar data:", error);
    }
  };
}
