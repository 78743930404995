import { gql } from 'react-apollo';

// Toaster
import { toastr } from 'react-redux-toastr';

const mutation = gql`
  mutation($id: String!, $rentCar: Int!) {
    updateRentCarStatus(id: $id, rentCar: $rentCar) {
      status
    }
  }
`;

export function updateRentCarStatus(id, rentCar) {
  return async (dispatch, getState, { client }) => {
    try {
      
      const { data } = await client.mutate({
        mutation,
        variables: { id, rentCar },
        fetchPolicy: 'no-cache',
      });
      if (data.updateRentCarStatus.status === 'success') {
        toastr.success('Update Rent Car', 'Rent Car has been successfully updated!');
      } else {
        toastr.error('Update Rent Car', 'Failed to Update Rent Car');
      }
    } catch (error) {
      toastr.warning('Select rentCar/UnrentCar', 'You have to select either one option rentCar/unrentCar');
      return false;
    }
    return true;
  };
}