import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { injectIntl, FormattedMessage } from 'react-intl';
import { Col, FormControl } from 'react-bootstrap'; // Add this import statement

// Style
import cx from 'classnames';
import cs from '../commonStyle.css';

import Calendar from './Calendar';
import { onChangeListing } from '../../actions/Listing/onChangeListing';
import { getCarCalendarData , resetCarCalendarData} from '../../actions/getCarCalendarData';

// Locale
import messages from '../../locale/messages';

class CarCalendar extends Component {

  constructor(props) {
    super(props);
    this.handleChange = this.handleChange.bind(this);
  }

  handleChange(e) {
    const { onChangeListing ,getCarCalendarData ,resetCarCalendarData} = this.props;
    const value = e.target.value;
    resetCarCalendarData();
    onChangeListing(value);
    getCarCalendarData(value);
  }

  render() {

    const { formatMessage } = this.props.intl;
    const { onChangeListingId, data: { refetch, loading, ManageListings } } = this.props;

    console.log("data:", refetch, loading);

    return (
      <Col xs={12} sm={12} md={12} lg={12}>
        <div className={cx(cs.spaceTop6, 'whiteBgColor', 'youcarsBg')}>
          <h3 className={cx(cs.commonSubTitleText, cs.fontWeightBold, cs.paddingBottom3, cs.textAlignCenter)}>
            <FormattedMessage {...messages.tabCalendar} />
          </h3>
          <FormControl componentClass="select" className={cs.formControlSelect} onChange={this.handleChange} value={onChangeListingId}>
            <option value="0">{formatMessage(messages.yourListings)}</option>
            {
              ManageListings && ManageListings.results && ManageListings.results.length > 0 && ManageListings.results.map((item) => {
                return (
                  <option value={item.id} key={item.id}>{item.title}</option>
                )
              })
            }
          </FormControl>
          <Calendar listId={onChangeListingId}  />
        </div>
      </Col>
    );
  }
}

const mapState = (state) => ({
  onChangeListingId: state.onChangeListing.onChangeListingId
});

const mapDispatch = {
  onChangeListing,
  getCarCalendarData,
  resetCarCalendarData
};

export default injectIntl(connect(mapState, mapDispatch)(CarCalendar));