exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".UserForm-displayGridDate-bMJPj {\n    display: grid;\n    grid-template-columns: 32% 36% 32%;\n}\n\n.UserForm-datePadding-SjgmP {\n    padding: 0px 8px;\n}\n\n.UserForm-checkBoxGrid-2ICiS {\n    display: grid;\n    grid-template-columns: 24px auto;\n}\n\n.UserForm-checkBoxText-odshw {\n    padding-left: 7px;\n}\n\n.UserForm-displayGrid-2M83l {\n    display: grid;\n    grid-template-columns: 50% 50%;\n}\n\n@media screen and (max-width: 767px) {\n    .UserForm-displayGridDate-bMJPj {\n        grid-template-columns: 100%;\n    }\n\n    .UserForm-datePadding-SjgmP {\n        padding: 0px;\n    }\n    .UserForm-displayGrid-2M83l {\n        grid-template-columns: 100%;\n    }\n}", ""]);

// exports
exports.locals = {
	"displayGridDate": "UserForm-displayGridDate-bMJPj",
	"datePadding": "UserForm-datePadding-SjgmP",
	"checkBoxGrid": "UserForm-checkBoxGrid-2ICiS",
	"checkBoxText": "UserForm-checkBoxText-odshw",
	"displayGrid": "UserForm-displayGrid-2M83l"
};