import emailMessages from '../messages/emailMessages';

export function getSubject(type, locale) {
  let subject, previewText;

  if (emailMessages[locale] && emailMessages[locale][type]) {
    subject = emailMessages[locale][type].subject;
    previewText = emailMessages[locale][type].previewText;
  } else {
    subject = emailMessages['en-US'][type].subject;
    previewText = emailMessages['en-US'][type].previewText;
  }

  return {
    subject,
    previewText
  };
}