import React from 'react';
import PropTypes from 'prop-types';
import withStyles from 'isomorphic-style-loader/lib/withStyles';
import s from './addUser.css';
import UserForm from '../../components/User/UserForm';
import { injectIntl } from 'react-intl';

class AddUser extends React.Component {
  static propTypes = {
    title: PropTypes.string.isRequired
  };

  render() {
    return (
      <>
        <UserForm />
      </>
    );
  }
}

export default injectIntl(withStyles(s)(AddUser));
