exports = module.exports = require("../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "\n.WishListGroupItem-imageContent-2vgdV {\n\twidth: 100%;\n    height: 100%;\n    background-position: 50% 50%;\n    background-repeat: no-repeat;\n    background-size: cover;\n    padding-top: 59%;\n    background-color: #F3F3F3;\n    border-radius: 6px;\n}\n.WishListGroupItem-defaultImageCss-1mqmR{\n    background-size: 54px;\n}", ""]);

// exports
exports.locals = {
	"imageContent": "WishListGroupItem-imageContent-2vgdV",
	"defaultImageCss": "WishListGroupItem-defaultImageCss-1mqmR"
};