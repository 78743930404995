import React, { Component } from 'react';

import withStyles from 'isomorphic-style-loader/lib/withStyles';
import s from './Book.css';

// Component
import Booking from '../../components/Booking';
class Book extends Component {
    render() {
        const { deliveryStatus ,idCustomer } = this.props
        return (
            <>
                <Booking idCustomer={idCustomer}/>
            </>
        );
    }
}

export default withStyles(s)(Book);