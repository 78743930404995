import React, { Component } from 'react';
import { connect } from 'react-redux';
import { reduxForm } from 'redux-form';
import PropTypes from 'prop-types';
import { toastr } from 'react-redux-toastr';
import * as XLSX from 'xlsx';

// Style
import cx from 'classnames';
import cs from '../../../../components/commonStyle.css';
import withStyles from 'isomorphic-style-loader/lib/withStyles';
import s from './ModalForm.css';
import {
    Modal, 
    Form
  } from 'react-bootstrap';

import Loader from '../../../Loader';
// Translation
import { FormattedMessage, injectIntl } from 'react-intl';
import messages from '../../../../locale/messages';
import submit from './submit';

class ImportCarModal extends Component {
  static propTypes = {
    submit: PropTypes.any,
  };

  constructor(props) {
    super(props);
    this.state = {
      excelData: [],
      excelDataLength: 0,
    };
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleImport = this.handleImport.bind(this);
  }

  async handleSubmit() {
    const { dispatch, closeModal } = this.props;
    const { excelData, excelDataLength } = this.state;
    
    if (!excelData || excelDataLength === 0) {
      return [];
    }

    for (let i = 0; i < excelData.length; i++) {
      await submit(excelData[i],dispatch);
    }
    toastr.success('Success!', 'Your successfully !');
    closeModal();
  }

  convertJsonData = (jsonData, userId) => {
    if (!jsonData || jsonData.length === 0) {
      return [];
    }
    
    const columns = jsonData[0];
    const rows = [];
    
    for (let i = 1; i < jsonData.length; i++) {
      const row = { userId };
      for (let j = 0; j < columns.length; j++) {
        const columnName = columns[j];
        let cellValue = jsonData[i][j];
        if (columnName === 'carFeatures' || columnName === 'carRules') {
          cellValue = typeof cellValue === 'string' ? cellValue.split(",").map(Number) : null;
        }
        row[columnName] = cellValue !== null ? cellValue : null;
      }
      rows.push(row);
    }
    
    return rows;
  };

  handleImport = async (e) => {
    const { importCarModalData, closeModal} = this.props;

    const file = e.target.files[0];

    if (!file) {
        toastr.error('Error!', 'No file selected.');
        closeModal();
    }

    if (file.size > 1024 * 1024 * parseInt(10)) {
        toastr.error('Maximum upload size exceeded!', 'Try with a smaller size image');
        closeModal();
    }

    if ( file.type !== 'application/vnd.ms-excel' &&
        file.type !== 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' ) {
        toastr.error( 'Error!', 'You are trying to upload an invalid file. Please upload XLS, or XLSX format files.');
        closeModal();
    }

    const reader = new FileReader();
    reader.onload = async (e) => {
        try {
            const data = new Uint8Array(e.target.result);
            const workbook = XLSX.read(data, { type: 'array' });
            const sheetName = workbook.SheetNames[0];
            const sheet = workbook.Sheets[sheetName];
            const jsonData = XLSX.utils.sheet_to_json(sheet, { header: 1 });
            const convertedData = this.convertJsonData(jsonData, importCarModalData.id);
            this.setState({ excelData: convertedData , excelDataLength : convertedData.length });
        } catch (error) {
            toastr.error('Error!', 'Failed to read Excel file.');
            closeModal();
        }
    };
    reader.readAsArrayBuffer(file);
  };

    render() {
      const { handleSubmit, show, closeModal, importCarModalData} = this.props;
      const { formatMessage } = this.props.intl;
      const { excelDataLength } = this.state;
        return (
            <div>
		        <Modal show={show} onHide={closeModal} dialogClassName={s.logInModalContainer} >
		          <Modal.Header closeButton>
		            <Modal.Title><FormattedMessage {...messages.listingsManagement} /></Modal.Title>
		          </Modal.Header>
		          <Modal.Body bsClass={s.logInModalBody}>
		            <div className={s.root}>
		              <div className={s.container}>
                    <Form onSubmit={handleSubmit(this.handleSubmit)}>
                      <div className={s.displayGrid}>
                        <label className={cs.spaceBottom8} ><FormattedMessage {...messages.name} /> : </label> <label className={s.labelMargin} > { importCarModalData?.name }</label>
                      </div>
                      
                      <div className={s.displayGrid}>
                        <label className={cs.spaceBottom8} >File Upload : </label> <input type='file' className={cx(cs.formControlInput, 'commonInputPaddingRTL')} accept='application/vnd.ms-excel, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' onChange={this.handleImport}/>
                      </div>

                      <div className={s.displayGrid}>
                        <label className={cs.spaceBottom8} >Number Car : </label><label className={s.labelMargin} > { excelDataLength }</label>
                      </div>
                      
                      <div className={cx(cs.textAlignRight, 'textAlignLeftRTL')}>
                          <Loader
                            type={"button"}
                            buttonType={"button"}
                            className={cx(cs.btnPrimary, cs.btnBig, cs.displayinlineBlock)}
                            label={formatMessage(messages.save)}
                            handleClick={this.handleSubmit}
                          />
                      </div>
                    </Form>
		              </div>
		            </div>
		          </Modal.Body>
		        </Modal>
		    </div>
        );
    }
}

ImportCarModal = reduxForm({
  form: 'ImportCarModal'
})(ImportCarModal);

const mapState = (state) => ({
});

const mapDispatch = {
  submit
};

export default injectIntl(withStyles(s)(connect(mapState, mapDispatch)(ImportCarModal)));