exports = module.exports = require("../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".ListingIntro-displayGrid-ebA6J {\n    display: grid;\n    -webkit-box-pack: justify;\n        -ms-flex-pack: justify;\n            justify-content: space-between;\n\tgrid-template-columns: 25% 25% 25% 22%;\n}\n\n.ListingIntro-gridBoxSection-qxpSn {\n\tbackground: #F9F9F9 0% 0% no-repeat padding-box;\n    border: 1px solid #E0E0E0;\n    border-radius: 12px;\n    padding: 30px 19px 20px;\n    text-align: center;\n\tmargin-right: 25px;\n}\n\n.ListingIntro-gridBoxSection-qxpSn:nth-child(4) {\n\tmargin-right: 0px;\n}\n\n.ListingIntro-gridColumn-2joXD {\n\tdisplay: grid;\n\tgrid-template-columns: 70% auto;\n\t-webkit-box-pack: justify;\n\t    -ms-flex-pack: justify;\n\t        justify-content: space-between;\n\t-webkit-box-align: center;\n\t    -ms-flex-align: center;\n\t        align-items: center;\n\tgap: 15px;\n}\n\n.ListingIntro-textSection-16BUi{\n\tword-break: break-word;\n}\n\n.ListingIntro-mobileMargin-2Z1ku{\n\ttext-align: center;\n}\n\n@media screen and (max-width: 767px) {\n\t.ListingIntro-displayGrid-ebA6J {\n\t\tgrid-template-columns: 100%;\n\t}\n\n\t.ListingIntro-gridBoxSection-qxpSn {\n\t\tmargin-right: 0px;\n\t}\n\n\t.ListingIntro-mobileMargin-2Z1ku {\n\t\tmargin-top: 18px;\n\t}\n\t.ListingIntro-gridColumn-2joXD {\n\t\tgrid-template-columns: 100%;\n\t}\n\t\n\t.ListingIntro-img_icon-1uj4R {\n\t  display: none;\n\t}\n}", ""]);

// exports
exports.locals = {
	"displayGrid": "ListingIntro-displayGrid-ebA6J",
	"gridBoxSection": "ListingIntro-gridBoxSection-qxpSn",
	"gridColumn": "ListingIntro-gridColumn-2joXD",
	"textSection": "ListingIntro-textSection-16BUi",
	"mobileMargin": "ListingIntro-mobileMargin-2Z1ku",
	"img_icon": "ListingIntro-img_icon-1uj4R"
};