import React from 'react';
import withStyles from 'isomorphic-style-loader/lib/withStyles';
import s from './hostCarAll.css';
import HostAllCar from '../../components/HostAllCar/HostAllCar';
import { injectIntl } from 'react-intl';

// Locale
import messages from '../../locale/messages';

class HostCarAll extends React.Component {

  render() {
    const { formatMessage } = this.props.intl;
    return (
      <>
        <HostAllCar placeholder={formatMessage(messages.documentUploadPlaceholder)} />
      </>
    );
  }
}

export default injectIntl(withStyles(s)(HostCarAll));
