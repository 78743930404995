import React from 'react';
import UserLayout from '../../components/Layout/UserLayout';
import CalendarCar from './calendarCar';

const title = 'Calendar Car';

export default async function action({ store }) {
  
  let isAuthenticated = store.getState().runtime.isAuthenticated;
  
  if (!isAuthenticated) {
    return { redirect: '/login' };
  }
  
  return {
    title,
    component: <UserLayout><CalendarCar title={title} /></UserLayout>,
  };
};
