import moment from 'moment';
import 'moment/locale/es';
import 'moment/locale/it';
import 'moment/locale/fr';
import 'moment/locale/pt';
import 'moment/locale/cs';


const localeDict = {
    'en-US': 'English',
    'es': 'Español',
    'it-IT': 'Italiano',
    'fr-FR': 'Français',
    'pt-PT': 'Português',
    'cs-CZ': 'Czech',
    'ar': 'العربية',
};


const rtlLocateDict = ['he', 'ar'];

export function formatLocale(locale) {
    return localeDict[locale] || 'English';
}

export function isRTL(locale) {
    moment.locale(locale);
    return locale && rtlLocateDict.indexOf(locale) >= 0;
}

export function generateMomentLocaleSettings(locale) {
    var localeData = moment.localeData(locale);
    let response = {
        ordinal: localeData.ordinal()
    };
    return response;
}