exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".WishListModalForm-displayFlex-SuJrs {\n    display: -webkit-box;\n    display: -ms-flexbox;\n    display: flex;\n    -webkit-box-align: center;\n        -ms-flex-align: center;\n            align-items: center;\n    -webkit-box-pack: justify;\n        -ms-flex-pack: justify;\n            justify-content: space-between;\n    width: 100%;\n}\n\n.WishListModalForm-listHeight-3_vrw {\n    max-height: 40vh;\n    overflow-y: auto;\n}\n\n.WishListModalForm-imgContainer-2zzp0 {\n    width: 100%;\n    max-width: 54px;\n    height: 54px;\n    background-position: 50% 50%;\n    background-size: cover;\n    background-repeat: no-repeat;\n    padding-top: 14%;\n    background-color: var(--common-gray-bg-color);\n    background-color: var(--common-gray-bg-color);\n    border-radius: 6px;\n}\n\n.WishListModalForm-defaultImage-2inBz{\n    background-color: #F3F3F3;\n    background-size: 30px;\n}\n\n.WishListModalForm-heartBtn-2fW3E, .WishListModalForm-heartBtn-2fW3E:hover, .WishListModalForm-heartBtn-2fW3E:active, .WishListModalForm-heartBtn-2fW3E:focus{\n    background-color: transparent !important;\n    -webkit-box-shadow: unset !important;\n            box-shadow: unset !important;\n    border: unset !important;\n    padding: 0 !important;\n    color: var(--common-text-linkcolor) !important;\n    color: var(--common-text-linkcolor) !important;\n    cursor: pointer;\n}\n\n.WishListModalForm-namePadding-q6Hl_{\n    padding: 0 18px;\n}\n\n.WishListModalForm-marginBottom-2ObSi{\n    margin-bottom: 18px;\n}\n\n.WishListModalForm-marginBottom-2ObSi:last-child{\n    margin-bottom: 0;\n}", ""]);

// exports
exports.locals = {
	"displayFlex": "WishListModalForm-displayFlex-SuJrs",
	"listHeight": "WishListModalForm-listHeight-3_vrw",
	"imgContainer": "WishListModalForm-imgContainer-2zzp0",
	"defaultImage": "WishListModalForm-defaultImage-2inBz",
	"heartBtn": "WishListModalForm-heartBtn-2fW3E",
	"namePadding": "WishListModalForm-namePadding-q6Hl_",
	"marginBottom": "WishListModalForm-marginBottom-2ObSi"
};