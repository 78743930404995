const emailMessages = {
  'en-US': {
    confirmEmailGreeting: 'Hi {name},',
    confirmEmailWelcomeMessage: 'Welcome to {siteName}! In order to get started, you need to confirm your email address.',
    confirmEmailConfirmButton: 'Confirm your email',
    confirmEmailThanksMessage: 'Thanks,',
    confirmEmailTeamMessage:'{siteName} Team',
    booking_request_host_new_trip: 'Great News! You have a new trip({confirmationCode}) from {guestName}',
    booking_request_host_trip_details: '{guestName} would take the car from {checkInDate} to {checkOutDate}.',
    booking_request_host_booking_payment: 'Based on price of {basePrice} per day with the associated cost, your estimated payment for this booking is {subtotal}.',
    booking_request_host_accept_decline: 'Accept or Decline',
    booking_request_guest_trip_request: 'Your trip request({confirmationCode}) starting on {checkInDate} has been sent to your car owner {hostName}. You will hear from them within 24 hours.',
    booking_request_guest_message_host: 'Message {hostName}',
    booking_confirmation_host_ride_confirmed: 'New ride confirmed! {guestName} arrives {checkInDateShort}',
    booking_confirmation_host_confirm_pickup_details: 'Send a message to confirm pickup details or welcome {guestName}.',
    booking_confirmation_host_member_since:'{siteName} member since {guestJoinedYear}', 
    booking_confirmation_host_contact_renter: 'Contact Renter',
    booking_confirmation_host_trip_start: 'Trip Start',
    booking_confirmation_host_trip_end: 'Trip End',
    booking_confirmation_host_confirmation_code: 'Confirmation Code',
    booking_confirmation_host_view_itinerary: 'View Itinerary',
    booking_confirmation_guest_trip_message: "Get ready - you are going for a trip",
    booking_confirmation_guest_confirmation_message: "{hostName} has confirmed your request. Please review the details of your trip and {contactOwnerLink} to coordinate the trip timings and key exchange.",
    booking_declined_guest : "We regret to inform that your owner {hostName} declined your request {confirmationCode} that was scheduled on {checkInDate}. As per the cancellation policy you will be refunded and notified.",
    booking_expired_guest : "We regret to inform that your booking with {hostName} ({confirmationCode}) that was scheduled on {checkInDate} has been expired. You will be fully refunded and notified.",
    booking_expired_host : "Booking for your car ({confirmationCode}) from {guestName} has expired. {guestName} will be fully refunded.",
    cancellation_refund_message: 'As per the cancellation policy, you will be refunded {refundAmount} and notified.',
    cancellation_cancellation_message: 'We regret to inform you that your owner {hostName} has cancelled your trip {confirmationCode} that was scheduled on {checkInDate}. {refundMessage}',
    cancelled_by_guest_regret_message: 'We regret to inform you that your renter {guestName} has cancelled your request {confirmationCode} that was scheduled on {checkInDate}.',
    cancelled_by_guest_payout_message: 'As per the cancellation policy, your payout will be updated to {payoutAmount}.',
    cancelled_by_guest_no_payout_message: 'As per the cancellation policy, you will not receive any payouts for this booking.',
    cancelled_by_guest_calendar_update_message: 'Your calendar has also been updated to show that the previously booked dates are now available.',
    completed_reservation_guest_booking_title: 'Tell {hostName} {hostLastName} what you loved',
    completed_reservation_guest_booking:' and what they can do better',
    completed_reservation_guest_trip_ended_message: 'You have just ended your trip, so now it is the perfect time to write your review.',
    completed_reservation_guest_review_importance_message: 'Reviews are an important part of the {siteName} community. Please take a moment to provide your owner with some helpful feedback - it\'ll only take few minutes.',
    completed_reservation_guest_write_review_button: 'Write a Review',
    completed_reservation_host_trip_ended_message: '{guestName} just ended his trip, so now it is the perfect time to write your review.',
    completed_reservation_host_review_importance_message: 'Reviews are an important part of the {siteName} community. Please take a moment to provide your renter with some helpful feedback - it\'ll only take few minutes.',
    forgot_password_email_request_received: 'We’ve received a request to reset your password.',
    forgot_password_email_ignore_message: 'If you didn’t make the request, just ignore this message. Otherwise, you can reset your password using this link:',
    forgot_password_email_reset_button: 'Click here to reset your password',
    new_message_message_from: 'You have a got a new message from {senderName}.',
    new_message_message: 'Message:',
    new_message_respond: 'Respond to {senderName}',
    new_inquiry_message_from: 'You have a got a new inquiry from {senderName}.',
    new_inquiry_trip_start_date: 'Trip Start Date: {checkInDate} {startTime}',
    new_inquiry_trip_end_date: 'Trip End Date: {checkOutDate} {endTime}',
    confirm_document_verification_welcome_message: 'Welcome to {siteName}!',
    confirm_document_verification_verification_status_message: 'Your documents have been {verificationStatus} in the document verification process.',
    confirm_document_verification_check_profile_button: 'Check your profile',
    booking_pre_approval_pre_approved_message: '{hostName} has pre-approved your request for {listTitle}. You can go ahead and {bookLink} the pre-approved dates now.',
    booking_pre_approval_book_link: 'book',
    ban_status_service_banned_greeting: 'Dear {userName},',
    ban_status_service_banned_message: 'You are banned.',
    ban_status_service_banned_contact_admin: 'Please get in touch with {adminMail} if you have any questions.',
    ban_status_service_unbanned : 'You are not banned now you can login.',
    contact_support_greeting: 'Hi Admin,',
    contact_support_message: 'A {userType} wanted to contact you for support, regarding reservation #{confirmationCode} on property ID {listId}.',
    contact_support_contact_name: 'Contact Name:',
    contact_support_contact_email: 'Contact Email:',
    contact_support_message_label: 'Message:',
    feedback_message: '{name} has sent you a {type} that {message}.',
    contact_email_contact_info: "A visitor/user wants to contact. Below are their contact information,",
    contact_email_name: "Name: {name}",
    contact_email_email: "Email: {email}",
    contact_email_contact_number: "Contact Number: {phoneNumber}",
    contact_email_message: "Message: {ContactMessage}",
    reportUserMailDefaultContent: "This is to inform you that the {userName} has violated the terms and condition of the platform. Please do the necessary action for this report. Reported by {reporterName}.",
    reportUserMailCustomContent: "Here, You receive an email to report the user {userName} by the report type {reportType} by {reporterName}.",
    welcomeEmail: {
      subject: 'Please confirm your e-mail address',
      previewText: 'Action Required! Confirm your email'
    },
    bookingRequest: {
      subject: 'You have a new trip',
      previewText: 'Great News! You have a new trip'
    },
    bookingRequestGuest: {
      subject: 'Your trip request sent to your owner',
      previewText: 'Great News! Your trip is shared with the owner'
    },
    bookingConfirmedToHost: {
      subject: 'You have confirmed a trip',
      previewText: 'Confirmed Reservation Details'
    },
    bookingConfirmedToGuest: {
      subject: 'Your trip is confirmed by your owner',
      previewText: 'Pack your bags, you are ready for your trip!'
    },
    bookingDeclinedToGuest: {
      subject: 'Your trip request is declined by your owner',
      previewText: 'We are sorry, your request is declined'
    },
    bookingExpiredGuest: {
      subject: 'Your trip request is expired',
      previewText: 'We are sorry, your request is expired'
    },
    bookingExpiredHost: {
      subject: 'Your trip is expired',
      previewText: 'Your trip is expired'
    },
    cancelledByHost: {
      subject: 'Your trip is cancelled by the owner',
      previewText: 'Your trip is cancelled'
    },
    cancelledByGuest: {
      subject: 'Your trip is cancelled by the renter',
      previewText: 'Your trip is cancelled'
    },
    completedGuest: {
      subject: 'Please write a review for your owner',
      previewText: 'Action Required! Write a Review'
    },
    completedHost: {
      subject: 'Please write a review for your renter',
      previewText: 'Action Required! Write a Review'
    },
    forgotPasswordLink: {
      subject: 'Reset your Password',
      previewText: 'Action Required! Reset your Password'
    },
    message: {
      subject: 'You have received a new message',
      previewText: 'Someone sent you a new message!'
    },
    inquiry: {
      subject: 'You have received a new inquiry',
      previewText: 'Someone sent you an inquiry from the contact form!'
    },
    documentVerification: {
      subject: 'Documents verification status',
      previewText: 'Documents verification status'
    },
    contact: {
      subject: 'Contact Form',
      previewText: 'Contact Form'
    },
    reportUser: {
      subject: 'Reporting the User',
      previewText: 'Report User Form'
    },
    bookingPreApproval: {
      subject: 'Owner pre-approved your request',
      previewText: 'Booking pre-approved'
    },
    banStatusServiceStatusBanned: {
      subject: 'Status Banned',
      previewText: 'Status Banned'
    },
    banStatusServiceStatusUnBanned: {
      subject: 'Status Unbanned',
      previewText: 'Status Unbanned'
    },
    contactSupport: {
      subject: 'Customer Support',
      previewText: 'Customer Support'
    },
    userFeedback: {
      subject: 'Customer Feedback',
      previewText: 'Customer Feedback'
    },
  },
  'es': {
    confirmEmailGreeting: '¡Hola {name},',
    confirmEmailWelcomeMessage: '¡Bienvenido a {siteName}! Para comenzar, necesitas confirmar tu dirección de correo electrónico.',
    confirmEmailConfirmButton: 'Confirmar tu correo electrónico',
    confirmEmailThanksMessage: 'Gracias,',
    confirmEmailTeamMessage:'Equipo {siteName}',
    booking_request_host_new_trip: '¡Excelentes noticias! Tienes un nuevo viaje({confirmationCode}) de {guestName}',
    booking_request_host_trip_details: '{guestName} tomaría el coche desde {checkInDate} hasta {checkOutDate}.',
    booking_request_host_booking_payment: 'Basado en el precio de {basePrice} por día con el costo asociado, tu pago estimado para esta reserva es {subtotal}.',
    booking_request_host_accept_decline: 'Aceptar o Rechazar',
    booking_request_guest_trip_request: 'Tu solicitud de viaje({confirmationCode}) comenzando el {checkInDate} ha sido enviada a tu propietario de coche {hostName}. Escucharás de ellos en un plazo de 24 horas.',
    booking_request_guest_message_host: 'Mensaje {hostName}',
    booking_confirmation_host_ride_confirmed: '¡Nuevo viaje confirmado! {guestName} llega {checkInDateShort}',
    booking_confirmation_host_confirm_pickup_details: 'Envía un mensaje para confirmar los detalles de recogida o dar la bienvenida a {guestName}.',
    booking_confirmation_host_member_since: "{siteName} miembro desde {guestJoinedYear}",
    booking_confirmation_host_contact_renter: 'Contactar al arrendatario',
    booking_confirmation_host_trip_start: 'Inicio del viaje',
    booking_confirmation_host_trip_end: 'Fin del viaje',
    booking_confirmation_host_confirmation_code: 'Código de confirmación',
    booking_confirmation_host_view_itinerary: 'Ver itinerario',
    booking_confirmation_guest_trip_message: "Prepárate: te vas de viaje",
    booking_confirmation_guest_confirmation_message: "{hostName} ha confirmado tu solicitud. Por favor, revisa los detalles de tu viaje y {contactOwnerLink} para coordinar los horarios del viaje y el intercambio de llaves.",
    booking_declined_guest:"Lamentamos informarte que tu dueño {hostName} rechazó tu solicitud {confirmationCode} que estaba programada para el {checkInDate}. Según la política de cancelación, se te reembolsará y se te notificará.",
    booking_expired_guest :"Lamentamos informarle que su reserva con {hostName} ({confirmationCode}) que estaba programada para el {checkInDate} ha caducado. Se le reembolsará por completo y se le notificará.",
    booking_expired_host : "La reserva para tu coche ({confirmationCode}) de {guestName} ha expirado. {guestName} será completamente reembolsado.",
    cancellation_refund_message: 'Según la política de cancelación, se le reembolsará {refundAmount} y se le notificará.',
    cancellation_cancellation_message: 'Lamentamos informarte que tu propietario {hostName} ha cancelado tu viaje {confirmationCode} que estaba programado para el {checkInDate}. {refundMessage}',
    cancelled_by_guest_regret_message: 'Lamentamos informarte que tu inquilino {guestName} ha cancelado tu solicitud {confirmationCode} que estaba programada para el {checkInDate}.',
    cancelled_by_guest_payout_message: 'Según la política de cancelación, su pago se actualizará a {payoutAmount}.',
    cancelled_by_guest_no_payout_message: 'Según la política de cancelación, no recibirá ningún pago por esta reserva.',
    cancelled_by_guest_calendar_update_message: 'Su calendario también se ha actualizado para mostrar que las fechas reservadas anteriormente ahora están disponibles.',
    completed_reservation_guest_booking_title: 'Dile a {hostName} {hostLastName} lo que te gustó',
    completed_reservation_guest_booking: ' y en qué pueden mejorar',
    completed_reservation_guest_trip_ended_message: 'Acabas de terminar tu viaje, así que ahora es el momento perfecto para escribir tu reseña.',
    completed_reservation_guest_review_importance_message: 'Las reseñas son una parte importante de la comunidad de {siteName}. Por favor, tómate un momento para proporcionar a tu propietario algunos comentarios útiles. Solo tomará unos minutos.',
    completed_reservation_guest_write_review_button: 'Escribir una reseña',
    completed_reservation_host_trip_ended_message: '{guestName} acaba de terminar su viaje, así que ahora es el momento perfecto para escribir su reseña.',
    completed_reservation_host_review_importance_message: 'Las reseñas son una parte importante de la comunidad {siteName}. Por favor, tómese un momento para proporcionar a su arrendador algunos comentarios útiles - solo tomará unos minutos.',
    forgot_password_email_request_received: 'Hemos recibido una solicitud para restablecer su contraseña.',
    forgot_password_email_ignore_message: 'Si no realizaste la solicitud, simplemente ignora este mensaje. De lo contrario, puedes restablecer tu contraseña usando este enlace:',
    forgot_password_email_reset_button: 'Haz clic aquí para restablecer tu contraseña',
    new_message_message_from: 'Has recibido un nuevo mensaje de {senderName}.',
    new_message_message: 'Mensaje:',
    new_message_respond: 'Responder a {senderName}',
    new_inquiry_message_from: 'Has recibido una nueva consulta de {senderName}.',
    new_inquiry_trip_start_date: 'Fecha de inicio del viaje: {checkInDate} {startTime}',
    new_inquiry_trip_end_date: 'Fecha de finalización del viaje: {checkOutDate} {endTime}',
    confirm_document_verification_welcome_message: '¡Bienvenido a {siteName}!',
    confirm_document_verification_verification_status_message: 'Sus documentos han sido {verificationStatus} en el proceso de verificación de documentos.',
    confirm_document_verification_check_profile_button: 'Verificar tu perfil',
    booking_pre_approval_pre_approved_message: '{hostName} ha preaprobado tu solicitud para {listTitle}. Puedes proceder y {bookLink} las fechas preaprobadas ahora.',
    booking_pre_approval_book_link: 'reservar',
    ban_status_service_banned_greeting: 'Estimado {userName},',
    ban_status_service_banned_message: 'Estás baneado.',
    ban_status_service_banned_contact_admin: 'Por favor, ponte en contacto con {adminMail} si tienes alguna pregunta.',
    ban_status_service_unbanned: 'Ahora no estás prohibido, puedes iniciar sesión.',
    contact_support_greeting: 'Hola Administrador,',
    contact_support_message: 'Un {userType} quería ponerse en contacto contigo para obtener soporte, en relación con la reserva #{confirmationCode} en la propiedad ID {listId}.',
    contact_support_contact_name: 'Nombre de Contacto:',
    contact_support_contact_email: 'Correo Electrónico de Contacto:',
    contact_support_message_label: 'Mensaje:',
    feedback_message: '{name} te ha enviado un {type} que {message}.',
    contact_email_contact_info: "Un visitante/usuario desea contactar. A continuación se muestran sus datos de contacto:",
    contact_email_name: "Nombre: {name}",
    contact_email_email: "Correo electrónico: {email}",
    contact_email_contact_number: "Número de contacto: {phoneNumber}",
    contact_email_message: "Mensaje: {ContactMessage}",
    reportUserMailDefaultContent: "Esto es para informarle que el usuario {userName} ha violado los términos y condiciones de la plataforma. Por favor, tome la acción necesaria para este informe. Reportado por {reporterName}.",
    reportUserMailCustomContent: "Aquí, recibe un correo electrónico para informar al usuario {userName} por el tipo de informe {reportType} por {reporterName}.",
    welcomeEmail: {
      subject: 'Por favor, confirma tu dirección de correo electrónico',
      previewText: '¡Acción requerida! Confirma tu correo electrónico'
    },
    bookingRequest: {
      subject: 'Tienes un nuevo viaje',
      previewText: '¡Buenas noticias! Tienes un nuevo viaje'
    },
    bookingRequestGuest: {
      subject: 'Tu solicitud de viaje enviada a tu propietario',
      previewText: '¡Buenas noticias! Tu viaje se compartió con el propietario'
    },
    bookingConfirmedToHost: {
      subject: 'Has confirmado un viaje',
      previewText: 'Detalles de la reserva confirmada'
    },
    bookingConfirmedToGuest: {
      subject: 'Tu viaje está confirmado por tu propietario',
      previewText: '¡Prepara tu equipaje, estás listo para tu viaje!'
    },
    bookingDeclinedToGuest: {
      subject: 'Tu solicitud de viaje ha sido rechazada por tu propietario',
      previewText: 'Lo sentimos, tu solicitud ha sido rechazada'
    },
    bookingExpiredGuest: {
      subject: 'Tu solicitud de viaje ha caducado',
      previewText: 'Lo sentimos, tu solicitud ha caducado'
    },
    bookingExpiredHost: {
      subject: 'Tu viaje ha caducado',
      previewText: 'Tu viaje ha caducado'
    },
    cancelledByHost: {
      subject: 'Tu viaje ha sido cancelado por el propietario',
      previewText: 'Tu viaje ha sido cancelado'
    },
    cancelledByGuest: {
      subject: 'Tu viaje ha sido cancelado por el arrendatario',
      previewText: 'Tu viaje ha sido cancelado'
    },
    completedGuest: {
      subject: 'Por favor, escribe una reseña para tu propietario',
      previewText: '¡Acción requerida! Escribe una reseña'
    },
    completedHost: {
      subject: 'Por favor, escribe una reseña para tu arrendatario',
      previewText: '¡Acción requerida! Escribe una reseña'
    },
    forgotPasswordLink: {
      subject: 'Restablece tu contraseña',
      previewText: '¡Acción requerida! Restablece tu contraseña'
    },
    message: {
      subject: 'Tienes un nuevo mensaje',
      previewText: '¡Alguien te ha enviado un nuevo mensaje!'
    },
    inquiry: {
      subject: 'Tienes una nueva consulta',
      previewText: '¡Alguien te ha enviado una consulta desde el formulario de contacto!'
    },
    documentVerification: {
      subject: 'Estado de verificación de documentos',
      previewText: 'Estado de verificación de documentos'
    },
    contact: {
      subject: 'Formulario de contacto',
      previewText: 'Formulario de contacto'
    },
    reportUser: {
      subject: 'Reporte del usuario',
      previewText: 'Formulario de reporte de usuario'
    },
    bookingPreApproval: {
      subject: 'El propietario ha preaprobado tu solicitud',
      previewText: 'Preaprobación de reserva'
    },
    banStatusServiceStatusBanned: {
      subject: 'Estado Baneado',
      previewText: 'Estado Baneado'
    },
    banStatusServiceStatusUnBanned: {
      subject: 'Estado Desbaneado',
      previewText: 'Estado Desbaneado'
    },
    contactSupport: {
      subject: 'Soporte al cliente',
      previewText: 'Soporte al cliente'
    },
    userFeedback: {
      subject: 'Opinión del usuario',
      previewText: 'Opinión del usuario'
    },
  },
  'it-IT': {
    confirmEmailGreeting: 'Ciao {name},',
    confirmEmailWelcomeMessage: 'Benvenuto su {siteName}! Per iniziare, devi confermare il tuo indirizzo email.',
    confirmEmailConfirmButton: 'Conferma la tua email',
    confirmEmailThanksMessage: 'Grazie,',
    confirmEmailTeamMessage:'Squadra {siteName}',
    booking_request_host_new_trip: 'Ottime notizie! Hai un nuovo viaggio({confirmationCode}) da {guestName}',
    booking_request_host_trip_details: '{guestName} prenderà la macchina da {checkInDate} a {checkOutDate}.',
    booking_request_host_booking_payment: 'In base al prezzo di {basePrice} al giorno con il costo associato, il tuo pagamento stimato per questa prenotazione è di {subtotal}.',
    booking_request_host_accept_decline: 'Accetta o Rifiuta',
    booking_request_guest_trip_request: 'La tua richiesta di viaggio({confirmationCode}) che inizia il {checkInDate} è stata inviata al proprietario della tua auto {hostName}. Riceverai loro entro 24 ore.',
    booking_request_guest_message_host: 'Messaggio {hostName}',
    booking_confirmation_host_ride_confirmed: 'Nuovo viaggio confermato! {guestName} arriva {checkInDateShort}',
    booking_confirmation_host_confirm_pickup_details: 'Invia un messaggio per confermare i dettagli del ritiro o dare il benvenuto a {guestName}.',
    booking_confirmation_host_member_since: "Membro di {siteName} dal {guestJoinedYear}",
    booking_confirmation_host_contact_renter: 'Contatta l\'affittuario',
    booking_confirmation_host_trip_start: 'Inizio viaggio',
    booking_confirmation_host_trip_end: 'Fine viaggio',
    booking_confirmation_host_confirmation_code: 'Codice di conferma',
    booking_confirmation_host_view_itinerary: 'Visualizza Itinerario',
    booking_confirmation_guest_trip_message: "Preparati: stai per partire per un viaggio",
    booking_confirmation_guest_confirmation_message: "{hostName} ha confermato la tua richiesta. Ti preghiamo di controllare i dettagli del tuo viaggio e di {contactOwnerLink} per coordinare gli orari del viaggio e lo scambio di chiavi.",
    booking_declined_guest : "Ci dispiace informarti che il proprietario {hostName} ha rifiutato la tua richiesta {confirmationCode} che era programmata per il {checkInDate}. Secondo la politica di cancellazione, verrai rimborsato e notificato.",
    booking_expired_guest : "Ci dispiace informarti che la tua prenotazione con {hostName} ({confirmationCode}) programmata per il {checkInDate} è scaduta. Sarai completamente rimborsato e notificato.",
    booking_expired_host : "La prenotazione per la tua auto ({confirmationCode}) da parte di {guestName} è scaduta. {guestName} sarà completamente rimborsato.",
    cancellation_refund_message: 'Según la política de cancelación, se le reembolsará {refundAmount} y se le notificará.',
    cancellation_cancellation_message: 'Lamentamos informarte que tu propietario {hostName} ha cancelado tu viaje {confirmationCode} que estaba programado para el {checkInDate}. {refundMessage}',
    cancelled_by_guest_regret_message: 'Siamo spiacenti di informarti che il tuo inquilino {guestName} ha cancellato la tua richiesta {confirmationCode} che era programmata per il {checkInDate}.',
    cancelled_by_guest_payout_message: 'Secondo la politica di cancellazione, il tuo pagamento verrà aggiornato a {payoutAmount}.',
    cancelled_by_guest_no_payout_message: 'Secondo la politica di cancellazione, non riceverai alcun pagamento per questa prenotazione.',
    cancelled_by_guest_calendar_update_message: 'Il tuo calendario è stato aggiornato per mostrare che le date prenotate in precedenza sono ora disponibili.',
    completed_reservation_guest_booking_title: 'Di a {hostName} {hostLastName} cosa ti è piaciuto',
    completed_reservation_guest_booking: ' e cosa possono migliorare',
    completed_reservation_guest_trip_ended_message: 'Hai appena concluso il tuo viaggio, quindi è il momento perfetto per scrivere la tua recensione.',
    completed_reservation_guest_review_importance_message: 'Le recensioni sono una parte importante della community di {siteName}. Per favore, prenditi un momento per fornire al tuo proprietario alcuni feedback utili. Ci vorranno solo pochi minuti.',
    completed_reservation_guest_write_review_button: 'Scrivi una recensione',
    completed_reservation_host_trip_ended_message: '{guestName} ha appena concluso il suo viaggio, quindi è il momento perfetto per scrivere la tua recensione.',
    completed_reservation_host_review_importance_message: 'Le recensioni sono una parte importante della community {siteName}. Per favore, prenditi un momento per fornire al tuo affittuario alcuni feedback utili - ci vorranno solo pochi minuti.',
    forgot_password_email_request_received: 'Abbiamo ricevuto una richiesta per reimpostare la tua password.',
    forgot_password_email_ignore_message: 'Se non hai fatto richiesta, ignora semplicemente questo messaggio. Altrimenti, puoi reimpostare la tua password utilizzando questo link:',
    forgot_password_email_reset_button: 'Clicca qui per reimpostare la tua password',
    new_message_message_from: 'Hai ricevuto un nuovo messaggio da {senderName}.',
    new_message_message: 'Messaggio:',
    new_message_respond: 'Rispondi a {senderName}',
    new_inquiry_message_from: 'Hai ricevuto una nuova richiesta da {senderName}.',
    new_inquiry_trip_start_date: 'Data di inizio del viaggio: {checkInDate} {startTime}',
    new_inquiry_trip_end_date: 'Data di fine del viaggio: {checkOutDate} {endTime}',
    confirm_document_verification_welcome_message: 'Benvenuto su {siteName}!',
    confirm_document_verification_verification_status_message: 'I tuoi documenti sono stati {verificationStatus} nel processo di verifica dei documenti.',
    confirm_document_verification_check_profile_button: 'Verifica il tuo profilo',
    booking_pre_approval_pre_approved_message: '{hostName} ha pre-approvato la tua richiesta per {listTitle}. Puoi procedere e {bookLink} le date pre-approvate ora.',
    booking_pre_approval_book_link: 'prenotare',
    ban_status_service_banned_greeting: 'Caro {userName},',
    ban_status_service_banned_message: 'Sei stato bannato.',
    ban_status_service_banned_contact_admin: 'Per favore, contatta {adminMail} se hai domande.',
    ban_status_service_unbanned: 'Non sei più bannato, ora puoi accedere.',
    contact_support_: 'Ciao Admin,',
    contact_support_message: 'Un {userType} voleva contattarti per assistenza, riguardante la prenotazione #{confirmationCode} sulla proprietà ID {listId}.',
    contact_support_contact_name: 'Nome del Contatto:',
    contact_support_contact_email: 'Email del Contatto:',
    contact_support_message_label: 'Messaggio:',
    feedback_message: '{name} ti ha inviato un {type} che {message}.',
    contact_email_contact_info: "Un visitatore/utente desidera contattarti. Di seguito trovi le loro informazioni di contatto:",
    contact_email_name: "Nome: {name}",
    contact_email_email: "Email: {email}",
    contact_email_contact_number: "Numero di contatto: {phoneNumber}",
    contact_email_message: "Messaggio: {ContactMessage}",
    reportUserMailDefaultContent: "Ti informiamo che l'utente {userName} ha violato i termini e le condizioni della piattaforma. Si prega di intraprendere le azioni necessarie per questa segnalazione. Segnalato da {reporterName}.",
    reportUserMailCustomContent: "Qui, ricevi un'email per segnalare l'utente {userName} per il tipo di segnalazione {reportType} da parte di {reporterName}.",
    welcomeEmail: {
      subject: 'Conferma il tuo indirizzo email',
      previewText: 'Azione richiesta! Conferma la tua email'
    },
    bookingRequest: {
      subject: 'Hai una nuova prenotazione',
      previewText: 'Ottime notizie! Hai una nuova prenotazione'
    },
    bookingRequestGuest: {
      subject: 'La tua richiesta di viaggio è stata inviata al proprietario',
      previewText: 'Ottime notizie! Il tuo viaggio è stato condiviso con il proprietario'
    },
    bookingConfirmedToHost: {
      subject: 'Hai confermato un viaggio',
      previewText: 'Dettagli della prenotazione confermata'
    },
    bookingConfirmedToGuest: {
      subject: 'Il tuo viaggio è confermato dal tuo proprietario',
      previewText: 'Prepara il tuo bagaglio, sei pronto per il tuo viaggio!'
    },
    bookingDeclinedToGuest: {
      subject: 'La tua richiesta di viaggio è stata rifiutata dal proprietario',
      previewText: 'Siamo spiacenti, la tua richiesta è stata rifiutata'
    },
    bookingExpiredGuest: {
      subject: 'La tua richiesta di viaggio è scaduta',
      previewText: 'Siamo spiacenti, la tua richiesta è scaduta'
    },
    bookingExpiredHost: {
      subject: 'Il tuo viaggio è scaduto',
      previewText: 'Il tuo viaggio è scaduto'
    },
    cancelledByHost: {
      subject: 'Il tuo viaggio è stato cancellato dal proprietario',
      previewText: 'Il tuo viaggio è stato cancellato'
    },
    cancelledByGuest: {
      subject: 'Il tuo viaggio è stato cancellato dall\'affittuario',
      previewText: 'Il tuo viaggio è stato cancellato'
    },
    completedGuest: {
      subject: 'Per favore, scrivi una recensione per il proprietario',
      previewText: 'Azione richiesta! Scrivi una recensione'
    },
    completedHost: {
      subject: 'Per favore, scrivi una recensione per l\'affittuario',
      previewText: 'Azione richiesta! Scrivi una recensione'
    },
    forgotPasswordLink: {
      subject: 'Reimposta la tua password',
      previewText: 'Azione richiesta! Reimposta la tua password'
    },
    message: {
      subject: 'Hai un nuovo messaggio',
      previewText: 'Qualcuno ti ha inviato un nuovo messaggio!'
    },
    inquiry: {
      subject: 'Hai una nuova richiesta',
      previewText: 'Qualcuno ti ha inviato una richiesta dal modulo di contatto!'
    },
    documentVerification: {
      subject: 'Stato di verifica dei documenti',
      previewText: 'Stato di verifica dei documenti'
    },
    contact: {
      subject: 'Modulo di contatto',
      previewText: 'Modulo di contatto'
    },
    reportUser: {
      subject: 'Segnalazione dell\'utente',
      previewText: 'Modulo di segnalazione dell\'utente'
    },
    bookingPreApproval: {
      subject: 'Il proprietario ha preapprovato la tua richiesta',
      previewText: 'Preapprovazione della prenotazione'
    },
    banStatusServiceStatusBanned: {
      subject: 'Stato Bandito',
      previewText: 'Stato Bandito'
    },
    banStatusServiceStatusUnBanned: {
      subject: 'Stato Sbannato',
      previewText: 'Stato Sbannato'
    },
    contactSupport: {
      subject: 'Supporto clienti',
      previewText: 'Supporto clienti'
    },
    userFeedback: {
      subject: 'Feedback dell\'utente',
      previewText: 'Feedback dell\'utente'
    },
  },
  'fr-FR': {
    confirmEmailGreeting: 'Salut {name},',
    confirmEmailWelcomeMessage: 'Bienvenue sur {siteName}! Pour commencer, vous devez confirmer votre adresse e-mail.',
    confirmEmailConfirmButton: 'Confirmer votre email',
    confirmEmailThanksMessage: 'Merci,',
    confirmEmailTeamMessage:'L\'équipe de {siteName}',
    booking_request_host_new_trip: 'Bonne nouvelle ! Vous avez un nouveau voyage ({confirmationCode}) de la part de {guestName}',
    booking_request_host_trip_details: '{guestName} prendrait la voiture de {checkInDate} à {checkOutDate}.',
    booking_request_host_booking_payment: 'Sur la base du prix de {basePrice} par jour avec les coûts associés, votre paiement estimé pour cette réservation est de {subtotal}.',
    booking_request_host_accept_decline: 'Accepter ou Refuser',
    booking_request_guest_trip_request: 'Votre demande de voyage ({confirmationCode}) commençant le {checkInDate} a été envoyée à votre propriétaire de voiture {hostName}. Vous entendrez parler d\'eux dans les 24 heures.',
    booking_request_guest_message_host: 'Message {hostName}',
    booking_confirmation_host_ride_confirmed: 'Nouveau trajet confirmé ! {guestName} arrive {checkInDateShort}',
    booking_confirmation_host_confirm_pickup_details: 'Envoyez un message pour confirmer les détails de prise en charge ou souhaiter la bienvenue à {guestName}.',
    booking_confirmation_host_member_since: "Membre de {siteName} depuis {guestJoinedYear}",
    booking_confirmation_host_contact_renter: 'Contacter le locataire',
    booking_confirmation_host_trip_start: 'Début du voyage',
    booking_confirmation_host_trip_end: 'Fin du voyage',
    booking_confirmation_host_confirmation_code: 'Code de confirmation',
    booking_confirmation_host_view_itinerary: 'Voir l\'itinéraire',
    booking_confirmation_guest_trip_message: "Préparez-vous - vous partez en voyage",
    booking_confirmation_guest_confirmation_message: "{hostName} a confirmé votre demande. Veuillez vérifier les détails de votre voyage et {contactOwnerLink} pour coordonner les horaires de voyage et l'échange de clés.",
    booking_declined_guest : "Nous regrettons de vous informer que votre propriétaire {hostName} a refusé votre demande {confirmationCode} qui était prévue pour le {checkInDate}. Conformément à la politique d'annulation, vous serez remboursé et notifié.",
    booking_expired_guest : "Nous regrettons de vous informer que votre réservation avec {hostName} ({confirmationCode}) qui était prévue pour le {checkInDate} a expiré. Vous serez entièrement remboursé et notifié.",
    booking_expired_host : "La réservation de votre voiture ({confirmationCode}) de {guestName} a expiré. {guestName} sera intégralement remboursé.",
    cancellation_refund_message: 'Conformément à la politique d\'annulation, vous serez remboursé de {refundAmount} et informé.',
    cancellation_cancellation_message: 'Nous regrettons de vous informer que votre propriétaire {hostName} a annulé votre voyage {confirmationCode} qui était prévu le {checkInDate}. {refundMessage}',
    cancelled_by_guest_regret_message: 'Nous avons le regret de vous informer que votre locataire {guestName} a annulé votre demande {confirmationCode} qui était prévue pour le {checkInDate}.',
    cancelled_by_guest_payout_message: 'Conformément à la politique d\'annulation, votre paiement sera mis à jour à {payoutAmount}.',
    cancelled_by_guest_no_payout_message: 'Conformément à la politique d\'annulation, vous ne recevrez aucun paiement pour cette réservation.',
    cancelled_by_guest_calendar_update_message: 'Votre calendrier a également été mis à jour pour indiquer que les dates précédemment réservées sont désormais disponibles.',
    completed_reservation_guest_booking_title: 'Dites à {hostName} {hostLastName} ce que vous avez aimé',
    completed_reservation_guest_booking: ' et ce qu\'ils peuvent améliorer',
    completed_reservation_guest_trip_ended_message: 'Vous venez de terminer votre voyage, c\'est donc le moment idéal pour rédiger votre avis.',
    completed_reservation_guest_review_importance_message: 'Les avis sont une partie importante de la communauté {siteName}. Veuillez prendre un moment pour fournir à votre propriétaire des commentaires utiles. Cela ne prendra que quelques minutes.',
    completed_reservation_guest_write_review_button: 'Écrire un avis',
    completed_reservation_host_trip_ended_message: '{guestName} vient de terminer son voyage, il est donc maintenant le moment idéal pour écrire votre avis.',
    completed_reservation_host_review_importance_message: 'Les avis sont une partie importante de la communauté {siteName}. Veuillez prendre un moment pour fournir à votre locataire quelques commentaires utiles - cela ne prendra que quelques minutes.',
    forgot_password_email_request_received: 'Nous avons reçu une demande de réinitialisation de votre mot de passe.',
    forgot_password_email_ignore_message: 'Si vous n\'avez pas effectué cette demande, ignorez simplement ce message. Sinon, vous pouvez réinitialiser votre mot de passe en utilisant ce lien :',
    forgot_password_email_reset_button: 'Cliquez ici pour réinitialiser votre mot de passe',
    new_message_message_from: 'Vous avez reçu un nouveau message de {senderName}.',
    new_message_message: 'Message :',
    new_message_respond: 'Répondre à {senderName}',
    new_inquiry_message_from: 'Vous avez reçu une nouvelle demande de renseignements de {senderName}.',
    new_inquiry_trip_start_date: 'Date de début du voyage : {checkInDate} {startTime}',
    new_inquiry_trip_end_date: 'Date de fin du voyage : {checkOutDate} {endTime}',
    confirm_document_verification_welcome_message: 'Bienvenue sur {siteName}!',
    confirm_document_verification_verification_status_message: 'Vos documents ont été {verificationStatus} dans le processus de vérification des documents.',
    confirm_document_verification_check_profile_button: 'Vérifiez votre profil',
    booking_pre_approval_pre_approved_message: '{hostName} a pré-approuvé votre demande pour {listTitle}. Vous pouvez procéder et {bookLink} les dates pré-approuvées maintenant.',
    booking_pre_approval_book_link: 'réserver',
    ban_status_service_banned_greeting: 'Cher {userName},',
    ban_status_service_banned_message: 'Vous êtes banni.',
    ban_status_service_banned_contact_admin: 'Veuillez entrer en contact avec {adminMail} si vous avez des questions.',
    ban_status_service_unbanned: 'Vous n\'êtes plus banni maintenant, vous pouvez vous connecter.',
    contact_support_greeting: 'Bonjour Administrateur,',
    contact_support_message: 'Un {userType} voulait vous contacter pour obtenir du support, concernant la réservation #{confirmationCode} sur l\'ID de propriété {listId}.',
    contact_support_contact_name: 'Nom du Contact :',
    contact_support_contact_email: 'Email du Contact :',
    contact_support_message_label: 'Message :',
    feedback_message: '{name} vous a envoyé un {type} qui {message}.',
    contact_email_contact_info: "Un visiteur/utilisateur souhaite contacter. Voici leurs coordonnées :",
    contact_email_name: "Nom : {name}",
    contact_email_email: "Email : {email}",
    contact_email_contact_number: "Numéro de contact : {phoneNumber}",
    contact_email_message: "Message : {ContactMessage}",
    reportUserMailDefaultContent: "Nous vous informons que l'utilisateur {userName} a enfreint les termes et conditions de la plateforme. Veuillez prendre les mesures nécessaires pour ce rapport. Signalé par {reporterName}.",
    reportUserMailCustomContent: "Ici, vous recevez un e-mail pour signaler l'utilisateur {userName} par le type de rapport {reportType} par {reporterName}.",
    welcomeEmail: {
      subject: 'Veuillez confirmer votre adresse e-mail',
      previewText: 'Action requise ! Confirmez votre e-mail'
    },
    bookingRequest: {
      subject: 'Vous avez un nouveau voyage',
      previewText: 'Bonne nouvelle ! Vous avez un nouveau voyage'
    },
    bookingRequestGuest: {
      subject: 'Votre demande de voyage envoyée à votre propriétaire',
      previewText: 'Bonne nouvelle ! Votre voyage est partagé avec le propriétaire'
    },
    bookingConfirmedToHost: {
      subject: 'Vous avez confirmé un voyage',
      previewText: 'Détails de la réservation confirmée'
    },
    bookingConfirmedToGuest: {
      subject: 'Votre voyage est confirmé par votre propriétaire',
      previewText: 'Préparez vos valises, vous êtes prêt pour votre voyage !'
    },
    bookingDeclinedToGuest: {
      subject: 'Votre demande de voyage est refusée par votre propriétaire',
      previewText: 'Nous sommes désolés, votre demande est refusée'
    },
    bookingExpiredGuest: {
      subject: 'Votre demande de voyage a expiré',
      previewText: 'Nous sommes désolés, votre demande a expiré'
    },
    bookingExpiredHost: {
      subject: 'Votre voyage a expiré',
      previewText: 'Votre voyage a expiré'
    },
    cancelledByHost: {
      subject: 'Votre voyage est annulé par le propriétaire',
      previewText: 'Votre voyage est annulé'
    },
    cancelledByGuest: {
      subject: 'Votre voyage est annulé par le locataire',
      previewText: 'Votre voyage est annulé'
    },
    completedGuest: {
      subject: 'Veuillez écrire un avis pour votre propriétaire',
      previewText: 'Action requise ! Écrivez un avis'
    },
    completedHost: {
      subject: 'Veuillez écrire un avis pour votre locataire',
      previewText: 'Action requise ! Écrivez un avis'
    },
    forgotPasswordLink: {
      subject: 'Réinitialisez votre mot de passe',
      previewText: 'Action requise ! Réinitialisez votre mot de passe'
    },
    message: {
      subject: 'Vous avez reçu un nouveau message',
      previewText: 'Quelqu\'un vous a envoyé un nouveau message !'
    },
    inquiry: {
      subject: 'Vous avez reçu une nouvelle demande',
      previewText: 'Quelqu\'un vous a envoyé une demande depuis le formulaire de contact !'
    },
    documentVerification: {
      subject: 'Statut de vérification des documents',
      previewText: 'Statut de vérification des documents'
    },
    contact: {
      subject: 'Formulaire de contact',
      previewText: 'Formulaire de contact'
    },
    reportUser: {
      subject: 'Signaler l\'utilisateur',
      previewText: 'Formulaire de signalement d\'utilisateur'
    },
    bookingPreApproval: {
      subject: 'Propriétaire pré-approuvé votre demande',
      previewText: 'Réservation pré-approuvée'
    },
    banStatusServiceStatusBanned: {
      subject: 'Statut banni',
      previewText: 'Statut banni'
    },
    banStatusServiceStatusUnBanned: {
      subject: 'Statut débanni',
      previewText: 'Statut débanni'
    },
    contactSupport: {
      subject: 'Support client',
      previewText: 'Support client'
    },
    userFeedback: {
      subject: 'Avis client',
      previewText: 'Avis client'
    },





  },
  'pt-PT': {
    confirmEmailGreeting: 'Olá {name},',
    confirmEmailWelcomeMessage: 'Bem-vindo ao {siteName}! Para começar, você precisa confirmar seu endereço de email.',
    confirmEmailConfirmButton: 'Confirmar seu email',
    confirmEmailThanksMessage: 'Obrigado,',
    confirmEmailTeamMessage:'A equipe {siteName}',
    booking_request_host_new_trip: 'Boas notícias! Você tem uma nova viagem ({confirmationCode}) de {guestName}',
    booking_request_host_trip_details: '{guestName} irá pegar o carro de {checkInDate} até {checkOutDate}.',
    booking_request_host_booking_payment: 'Com base no preço de {basePrice} por dia com o custo associado, seu pagamento estimado para esta reserva é de {subtotal}.',
    booking_request_host_accept_decline: 'Aceitar ou Recusar',
    booking_request_guest_trip_request: 'A sua solicitação de viagem ({confirmationCode}) começando em {checkInDate} foi enviada ao proprietário do seu carro {hostName}. Você receberá uma resposta deles dentro de 24 horas.',
    booking_request_guest_message_host: 'Mensagem {hostName}',
    booking_confirmation_host_ride_confirmed: 'Nova viagem confirmada! {guestName} chega {checkInDateShort}',
    booking_confirmation_host_confirm_pickup_details: 'Envie uma mensagem para confirmar os detalhes da coleta ou dar as boas-vindas a {guestName}.',
    booking_confirmation_host_member_since: "Membro de {siteName} desde {guestJoinedYear}",
    booking_confirmation_host_contact_renter: 'Contatar o Locatário',
    booking_confirmation_host_trip_start: 'Início da Viagem',
    booking_confirmation_host_trip_end: 'Fim da Viagem',
    booking_confirmation_host_confirmation_code: 'Código de Confirmação',
    booking_confirmation_host_view_itinerary: 'Ver Itinerário',
    booking_confirmation_guest_trip_message: "Prepare-se - você está indo para uma viagem",
    booking_confirmation_guest_confirmation_message: "{hostName} confirmou sua solicitação. Por favor, revise os detalhes da sua viagem e {contactOwnerLink} para coordenar os horários da viagem e a troca de chaves.",
    booking_declined_guest : "Lamentamos informar que o proprietário {hostName} recusou sua solicitação {confirmationCode} que estava programada para o {checkInDate}. Conforme a política de cancelamento, você será reembolsado e notificado.",
    booking_expired_guest : "Lamentamos informar que a sua reserva com {hostName} ({confirmationCode}) que estava agendada para o dia {checkInDate} expirou. Você será completamente reembolsado e notificado.",
    booking_expired_host : "A reserva para o seu carro ({confirmationCode}) de {guestName} expirou. {guestName} será totalmente reembolsado.",
    cancellation_refund_message: 'De acordo com a política de cancelamento, você será reembolsado em {refundAmount} e notificado.',
    cancellation_cancellation_message: 'Lamentamos informar que o seu proprietário {hostName} cancelou a sua viagem {confirmationCode} que estava agendada para o dia {checkInDate}. {refundMessage}',
    cancelled_by_guest_regret_message: 'Lamentamos informar que o seu inquilino {guestName} cancelou o seu pedido {confirmationCode} que estava agendado para {checkInDate}.',
    cancelled_by_guest_payout_message: 'De acordo com a política de cancelamento, o seu pagamento será atualizado para {payoutAmount}.',
    cancelled_by_guest_no_payout_message: 'De acordo com a política de cancelamento, você não receberá nenhum pagamento por esta reserva.',
    cancelled_by_guest_calendar_update_message: 'O seu calendário também foi atualizado para mostrar que as datas anteriormente reservadas estão agora disponíveis.',
    completed_reservation_guest_booking_title: 'Diga a {hostName} {hostLastName} o que você gostou',
    completed_reservation_guest_booking: '  e no que podem melhorar',
    completed_reservation_guest_trip_ended_message: 'Você acabou de terminar sua viagem, então agora é o momento perfeito para escrever sua avaliação.',
    completed_reservation_guest_review_importance_message: 'As avaliações são uma parte importante da comunidade {siteName}. Por favor, reserve um momento para fornecer ao seu proprietário algum feedback útil. Levará apenas alguns minutos.',
    completed_reservation_guest_write_review_button: 'Escrever uma avaliação',
    completed_reservation_host_trip_ended_message: '{guestName} acabou de concluir sua viagem, então agora é o momento perfeito para escrever sua avaliação.',
    completed_reservation_host_review_importance_message: 'As avaliações são uma parte importante da comunidade {siteName}. Por favor, reserve um momento para fornecer ao seu locatário algum feedback útil - levará apenas alguns minutos.',
    forgot_password_email_request_received: 'Recebemos um pedido para redefinir sua senha.',
    forgot_password_email_ignore_message: 'Se você não fez o pedido, apenas ignore esta mensagem. Caso contrário, você pode redefinir sua senha usando este link:',
    forgot_password_email_reset_button: 'Clique aqui para redefinir sua senha',
    new_message_message_from: 'Você recebeu uma nova mensagem de {senderName}.',
    new_message_message: 'Mensagem:',
    new_message_respond: 'Responder a {senderName}',
    new_inquiry_message_from: 'Você recebeu uma nova consulta de {senderName}.',
    new_inquiry_trip_start_date: 'Data de início da viagem: {checkInDate} {startTime}',
    new_inquiry_trip_end_date: 'Data de término da viagem: {checkOutDate} {endTime}',
    confirm_document_verification_welcome_message: 'Bem-vindo ao {siteName}!',
    confirm_document_verification_verification_status_message: 'Os seus documentos foram {verificationStatus} no processo de verificação de documentos.',
    confirm_document_verification_check_profile_button: 'Verifique o seu perfil',
    booking_pre_approval_pre_approved_message: '{hostName} pré-aprovou sua solicitação para {listTitle}. Você pode prosseguir e {bookLink} as datas pré-aprovadas agora.',
    booking_pre_approval_book_link: 'reservar',
    ban_status_service_banned_greeting: 'Caro {userName},',
    ban_status_service_banned_message: 'Você está banido.',
    ban_status_service_banned_contact_admin: 'Por favor, entre em contato com {adminMail} se tiver alguma dúvida.',
    ban_status_service_unbanned: 'Você não está banido agora, você pode fazer login.',
    contact_support_greeting: 'Olá Administrador,',
    contact_support_message: 'Um {userType} queria contatar você para suporte, em relação à reserva #{confirmationCode} no ID da propriedade {listId}.',
    contact_support_contact_name: 'Nome do Contato:',
    contact_support_contact_email: 'E-mail do Contato:',
    contact_support_message_label: 'Mensagem:',
    feedback_message: '{name} enviou-lhe um {type} que {message}.',
    contact_email_contact_info: "Um visitante/usuário deseja entrar em contato. Abaixo estão suas informações de contato:",
    contact_email_name: "Nome: {name}",
    contact_email_email: "Email: {email}",
    contact_email_contact_number: "Número de contato: {phoneNumber}",
    contact_email_message: "Mensagem: {ContactMessage}",
    reportUserMailDefaultContent: "Isso é para informar que o usuário {userName} violou os termos e condições da plataforma. Por favor, faça a ação necessária para este relatório. Relatado por {reporterName}.",
    reportUserMailCustomContent: "Aqui, você recebe um e-mail para relatar o usuário {userName} pelo tipo de relatório {reportType} por {reporterName}.",
    welcomeEmail: {
      subject: 'Por favor, confirme o seu endereço de e-mail',
      previewText: 'Ação necessária! Confirme o seu e-mail'
    },
    bookingRequest: {
      subject: 'Tem um novo passeio',
      previewText: 'Ótimas notícias! Tem um novo passeio'
    },
    bookingRequestGuest: {
      subject: 'O seu pedido de passeio foi enviado para o seu proprietário',
      previewText: 'Ótimas notícias! O seu passeio está partilhado com o proprietário'
    },
    bookingConfirmedToHost: {
      subject: 'Confirmou um passeio',
      previewText: 'Detalhes da reserva confirmada'
    },
    bookingConfirmedToGuest: {
      subject: 'O seu passeio foi confirmado pelo proprietário',
      previewText: 'Prepare as malas, está pronto para a sua viagem!'
    },
    bookingDeclinedToGuest: {
      subject: 'O seu pedido de passeio foi recusado pelo proprietário',
      previewText: 'Lamentamos, o seu pedido foi recusado'
    },
    bookingExpiredGuest: {
      subject: 'O seu pedido de passeio expirou',
      previewText: 'Lamentamos, o seu pedido expirou'
    },
    bookingExpiredHost: {
      subject: 'O seu passeio expirou',
      previewText: 'O seu passeio expirou'
    },
    cancelledByHost: {
      subject: 'O seu passeio foi cancelado pelo proprietário',
      previewText: 'O seu passeio foi cancelado'
    },
    cancelledByGuest: {
      subject: 'O seu passeio foi cancelado pelo arrendatário',
      previewText: 'O seu passeio foi cancelado'
    },
    completedGuest: {
      subject: 'Por favor, escreva uma avaliação para o seu proprietário',
      previewText: 'Ação necessária! Escreva uma avaliação'
    },
    completedHost: {
      subject: 'Por favor, escreva uma avaliação para o seu arrendatário',
      previewText: 'Ação necessária! Escreva uma avaliação'
    },
    forgotPasswordLink: {
      subject: 'Redefinir a sua palavra-passe',
      previewText: 'Ação necessária! Redefina a sua palavra-passe'
    },
    message: {
      subject: 'Recebeu uma nova mensagem',
      previewText: 'Alguém enviou-lhe uma nova mensagem!'
    },
    inquiry: {
      subject: 'Recebeu um novo pedido',
      previewText: 'Alguém enviou-lhe um pedido através do formulário de contacto!'
    },
    documentVerification: {
      subject: 'Estado de verificação de documentos',
      previewText: 'Estado de verificação de documentos'
    },
    contact: {
      subject: 'Formulário de contacto',
      previewText: 'Formulário de contacto'
    },
    reportUser: {
      subject: 'A reportar o utilizador',
      previewText: 'Formulário de relatório de utilizador'
    },
    bookingPreApproval: {
      subject: 'O proprietário pré-aprovou o seu pedido',
      previewText: 'Reserva pré-aprovada'
    },
    banStatusServiceStatusBanned: {
      subject: 'Estado Banido',
      previewText: 'Estado Banido'
    },
    banStatusServiceStatusUnBanned: {
      subject: 'Estado Desbanido',
      previewText: 'Estado Desbanido'
    },
    contactSupport: {
      subject: 'Suporte ao Cliente',
      previewText: 'Suporte ao Cliente'
    },
    userFeedback: {
      subject: 'Feedback do Cliente',
      previewText: 'Feedback do Cliente'
    },


  },
  'ar': {
    confirmEmailGreeting: 'مرحباً {name},',
    confirmEmailWelcomeMessage: 'مرحباً بك في {siteName}! للبدء، يجب عليك تأكيد عنوان بريدك الإلكتروني.',
    confirmEmailConfirmButton: 'تأكيد بريدك الإلكتروني',
    confirmEmailThanksMessage: 'شكراً لك،',
    confirmEmailTeamMessage:'فريق {siteName}',
    booking_request_host_new_trip: 'أخبار رائعة! لديك رحلة جديدة ({confirmationCode}) من {guestName}',
    booking_request_host_trip_details: 'سيقوم {guestName} بأخذ السيارة من {checkInDate} إلى {checkOutDate}.',
    booking_request_host_booking_payment: 'بناءً على سعر {basePrice} في اليوم مع التكلفة المرتبطة، الدفعة المقدرة الخاصة بك لهذه الحجز هي {subtotal}.',
    booking_request_host_accept_decline: 'قبول أو رفض',
    booking_request_guest_trip_request: 'طلبك للرحلة ({confirmationCode}) بدءًا من {checkInDate} تم إرساله إلى مالك السيارة الخاص بك {hostName}. ستسمع منهم خلال 24 ساعة.',
    booking_request_guest_message_host: 'رسالة {hostName}',
    booking_confirmation_host_ride_confirmed: 'تأكيد رحلة جديدة! {guestName} يصل {checkInDateShort}',
    booking_confirmation_host_confirm_pickup_details: 'أرسل رسالة لتأكيد تفاصيل الاستلام أو الترحيب بـ {guestName}.',
    booking_confirmation_host_member_since: "عضو في {siteName} منذ {guestJoinedYear}",
    booking_confirmation_host_contact_renter: 'الاتصال بالمستأجر',
    booking_confirmation_host_trip_start: 'بداية الرحلة',
    booking_confirmation_host_trip_end: 'نهاية الرحلة',
    booking_confirmation_host_confirmation_code: 'كود التأكيد',
    booking_confirmation_host_view_itinerary: 'عرض الخريطة',
    booking_confirmation_guest_trip_message: "استعد - أنت ذاهب في رحلة",
    booking_confirmation_guest_confirmation_message: "لقد قام {hostName} بتأكيد طلبك. يرجى مراجعة تفاصيل رحلتك و {contactOwnerLink} لتنسيق أوقات الرحلة وتبادل المفاتيح.",
    booking_declined_guest : "نأسف لإبلاغك أن مالكك {hostName} رفض طلبك {confirmationCode} الذي كان مقررًا في {checkInDate}. وفقًا لسياسة الإلغاء ، سيتم رد الأموال لك وسيتم إعلامك.",
    booking_expired_guest : "نأسف لإبلاغك بأن حجزك مع {hostName} ({confirmationCode}) المقرر في {checkInDate} قد انتهت صلاحيته. سيتم استرداد المبلغ بالكامل والإشعار.",
    booking_expired_host : "انتهت حجز سيارتك ({confirmationCode}) من {guestName}. سيتم استرداد المبلغ بالكامل لـ {guestName}.",
    cancellation_refund_message: 'وفقًا لسياسة الإلغاء، ستُسترد لك مبلغ {refundAmount} وستتلقى إشعارًا.',
    cancellation_cancellation_message: 'نأسف لإبلاغك أن مالكك {hostName} قام بإلغاء رحلتك {confirmationCode} المقررة في {checkInDate}. {refundMessage}',
    cancelled_by_guest_regret_message: 'نأسف لإبلاغك أن المستأجر {guestName} قام بإلغاء طلبك {confirmationCode} الذي كان مجدولًا في {checkInDate}.',
    cancelled_by_guest_payout_message: 'وفقًا لسياسة الإلغاء، سيتم تحديث دفعتك إلى {payoutAmount}.',
    cancelled_by_guest_no_payout_message: 'وفقًا لسياسة الإلغاء، لن تتلقى أي مدفوعات لهذا الحجز.',
    cancelled_by_guest_calendar_update_message: 'تم تحديث التقويم الخاص بك أيضًا ليظهر أن التواريخ المحجوزة مسبقًا متاحة الآن.',
    completed_reservation_guest_booking_title: 'أخبر {hostName} {hostLastName} بما أعجبك',
    completed_reservation_guest_booking:' وما يمكنهم تحسينه',
    completed_reservation_guest_trip_ended_message: 'لقد انتهيت للتو من رحلتك، لذا الآن هو الوقت المثالي لكتابة تقييمك.',
    completed_reservation_guest_review_importance_message: 'التقييمات جزء مهم من مجتمع {siteName}. يرجى القضاء لحظة لتزويد المالك ببعض التعليقات المفيدة - سيستغرق الأمر بضع دقائق فقط.',
    completed_reservation_guest_write_review_button: 'كتابة تقييم',
    completed_reservation_host_trip_ended_message: '{guestName} انتهى للتو من رحلته، لذا الآن هو الوقت المثالي لكتابة تقييمك.',
    completed_reservation_host_review_importance_message: 'التقييمات جزء مهم من مجتمع {siteName}. يرجى قضاء بضع دقائق لتقديم بعض التعليقات المفيدة لمستأجرك - ستستغرق دقائق قليلة فقط.',
    forgot_password_email_request_received: 'لقد تلقينا طلبًا لإعادة تعيين كلمة المرور الخاصة بك.',
    forgot_password_email_ignore_message: 'إذا لم تقم بالطلب، فقط تجاهل هذه الرسالة. وإلا، يمكنك إعادة تعيين كلمة المرور الخاصة بك باستخدام هذا الرابط:',
    forgot_password_email_reset_button: 'انقر هنا لإعادة تعيين كلمة المرور الخاصة بك',
    new_message_message_from: 'لقد تلقيت رسالة جديدة من {senderName}.',
    new_message_message: 'الرسالة:',
    new_message_respond: 'الرد على {senderName}',
    new_inquiry_message_from: 'لقد تلقيت استفسارًا جديدًا من {senderName}.',
    new_inquiry_trip_start_date: 'تاريخ بدء الرحلة: {checkInDate} {startTime}',
    new_inquiry_trip_end_date: 'تاريخ انتهاء الرحلة: {checkOutDate} {endTime}',
    confirm_document_verification_welcome_message: 'مرحبًا بك في {siteName}!',
    confirm_document_verification_verification_status_message: 'تمت {verificationStatus} وثائقك في عملية التحقق من الوثائق.',
    confirm_document_verification_check_profile_button: 'تحقق من ملفك الشخصي',
    booking_pre_approval_pre_approved_message: '{hostName} قد وافق مسبقاً على طلبك لـ {listTitle}. يمكنك المضي قدماً و {bookLink} التواريخ الموافق عليها مسبقاً الآن.',
    booking_pre_approval_book_link: 'حجز',
    ban_status_service_banned_greeting: 'عزيزي {userName},',
    ban_status_service_banned_message: 'لقد تم حظرك.',
    ban_status_service_banned_contact_admin: 'كان لديك أي أسئلة.{adminMail} يرجى الحصول على اتصال مع',
    ban_status_service_unbanned: 'أنت لم تعد محظورًا الآن يمكنك تسجيل الدخول.',
    contact_support_greeting: 'مرحباً مدير النظام،',
    contact_support_message: 'يرغب {userType} في الاتصال بك للحصول على الدعم، بخصوص الحجز رقم #{confirmationCode} على معرف الملكية {listId}.',
    contact_support_contact_name: 'اسم الشخص الذي يتواصل:',
    contact_support_contact_email: 'بريد الكتروني للتواصل:',
    contact_support_message_label: 'الرسالة:',
    feedback_message: 'قام {name} بإرسال {type} أن {message}.',
    contact_email_contact_info: "يرغب زائر/مستخدم في التواصل. فيما يلي معلومات الاتصال الخاصة بهم:",
    contact_email_name: "الاسم: {name}",
    contact_email_email: "البريد الإلكتروني: {email}",
    contact_email_contact_number: "رقم الاتصال: {phoneNumber}",
    contact_email_message: "الرسالة: {ContactMessage}",
    reportUserMailDefaultContent: "نرغب في إبلاغكم بأن المستخدم {userName} قد خالف شروط وأحكام المنصة. يرجى اتخاذ الإجراءات اللازمة لهذا التقرير. تم الإبلاغ عنه من قبل {reporterName}.",
    reportUserMailCustomContent: "هنا، تتلقى بريدًا إلكترونيًا للإبلاغ عن المستخدم {userName} بنوع التقرير {reportType} بواسطة {reporterName}.",
    welcomeEmail: {
      subject: 'يرجى تأكيد عنوان بريدك الإلكتروني',
      previewText: 'يتطلب الإجراء! قم بتأكيد بريدك الإلكتروني'
    },
    bookingRequest: {
      subject: 'لديك رحلة جديدة',
      previewText: 'أخبار رائعة! لديك رحلة جديدة'
    },
    bookingRequestGuest: {
      subject: 'تم إرسال طلب رحلتك إلى صاحب المنزل الخاص بك',
      previewText: 'أخبار رائعة! تم مشاركة رحلتك مع صاحب المنزل'
    },
    bookingConfirmedToHost: {
      subject: 'لقد قمت بتأكيد رحلة',
      previewText: 'تفاصيل الحجز المؤكدة'
    },
    bookingConfirmedToGuest: {
      subject: 'تم تأكيد رحلتك من قبل صاحب المنزل الخاص بك',
      previewText: 'قم بتجهيز حقائبك، أنت جاهز لرحلتك!'
    },
    bookingDeclinedToGuest: {
      subject: 'تم رفض طلب رحلتك من قبل صاحب المنزل الخاص بك',
      previewText: 'نحن نعتذر، تم رفض طلبك'
    },
    bookingExpiredGuest: {
      subject: 'انتهى طلب رحلتك',
      previewText: 'نحن نعتذر، انتهى طلبك'
    },
    bookingExpiredHost: {
      subject: 'انتهت رحلتك',
      previewText: 'انتهت رحلتك'
    },
    cancelledByHost: {
      subject: 'تم إلغاء رحلتك من قبل صاحب المنزل',
      previewText: 'تم إلغاء رحلتك'
    },
    cancelledByGuest: {
      subject: 'تم إلغاء رحلتك من قبل المستأجر',
      previewText: 'تم إلغاء رحلتك'
    },
    completedGuest: {
      subject: 'يرجى كتابة مراجعة لصاحب المنزل الخاص بك',
      previewText: 'يتطلب الإجراء! كتابة مراجعة'
    },
    completedHost: {
      subject: 'يرجى كتابة مراجعة للمستأجر الخاص بك',
      previewText: 'يتطلب الإجراء! كتابة مراجعة'
    },
    forgotPasswordLink: {
      subject: 'إعادة تعيين كلمة المرور الخاصة بك',
      previewText: 'يتطلب الإجراء! إعادة تعيين كلمة المرور الخاصة بك'
    },
    message: {
      subject: 'لقد تلقيت رسالة جديدة',
      previewText: 'قام شخص ما بإرسال رسالة جديدة لك!'
    },
    inquiry: {
      subject: 'لقد تلقيت استفسارًا جديدًا',
      previewText: 'قام شخص ما بإرسال استفسار لك من نموذج الاتصال!'
    },
    documentVerification: {
      subject: 'حالة التحقق من المستندات',
      previewText: 'حالة التحقق من المستندات'
    },
    contact: {
      subject: 'نموذج الاتصال',
      previewText: 'نموذج الاتصال'
    },
    reportUser: {
      subject: 'الإبلاغ عن المستخدم',
      previewText: 'نموذج الإبلاغ عن المستخدم'
    },
    bookingPreApproval: {
      subject: 'وافق المالك مسبقًا على طلبك',
      previewText: 'تم الموافقة مسبقًا على الحجز'
    },
    banStatusServiceStatusBanned: {
      subject: 'الحالة: محظور',
      previewText: 'الحالة: محظور'
    },
    banStatusServiceStatusUnBanned: {
      subject: 'الحالة: غير محظور',
      previewText: 'الحالة: غير محظور'
    },
    contactSupport: {
      subject: 'الدعم الفني',
      previewText: 'الدعم الفني'
    },
    userFeedback: {
      subject: 'تقييمات العملاء',
      previewText: 'تقييمات العملاء'
    },
  },
};

export default emailMessages;