import React from 'react';
import PropTypes from 'prop-types';
import Layout from '../layouts/Layout';
import Header from '../modules/Header';
import Body from '../modules/Body';
import Footer from '../modules/Footer';
import EmptySpace from '../modules/EmptySpace';
import { url } from '../../../config';
import { COMMON_TEXT_COLOR } from '../../../constants/index';
import { FormattedMessage, injectIntl} from 'react-intl';

class ReportUserMail extends React.Component {

    static propTypes = {
        content: PropTypes.shape({
            userName: PropTypes.string.isRequired,
            reporterName: PropTypes.string.isRequired,
            reportType: PropTypes.string.isRequired,
            siteName: PropTypes.string.isRequired,
        })
    };

    render() {

        const textStyle = {
            color: COMMON_TEXT_COLOR,
            backgroundColor: '#F7F7F7',
            fontFamily: 'Arial',
            fontSize: '16px',
            padding: '35px'
        };
        const { content: { userName, reporterName, reportType, logo, defaultContent, siteName } } = this.props;

        return (
            <Layout>
                <Header
                    color="rgb(255, 90, 95)"
                    backgroundColor="#F7F7F7"
                    logo={logo}
                    siteName={siteName}
                />
                <Body textStyle={textStyle}>
                    <div>
                        <FormattedMessage id="contact_support_greeting"  />
                    </div>
                    <EmptySpace height={20} />
                    {defaultContent ?
                        <div>
                            <FormattedMessage id="reportUserMailDefaultContent" values={{ userName, reporterName }} />
                        </div> :
                        <div>
                            <FormattedMessage id="reportUserMailCustomContent" values={{ userName, reportType, reporterName }} />
                        </div>}
                    <EmptySpace height={20} />
                    <div>
                        <FormattedMessage id="confirmEmailThanksMessage" /><br/>
                        <FormattedMessage id="confirmEmailTeamMessage" values={{ siteName }} />
                    </div>
                </Body>
                <Footer siteName={siteName}/>
                <EmptySpace height={20} />
            </Layout>
        );
    }

}

export default injectIntl(ReportUserMail);