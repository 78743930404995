import React from 'react';
import PropTypes from 'prop-types';
import { graphql, compose } from 'react-apollo';

// Style
import withStyles from 'isomorphic-style-loader/lib/withStyles';
import s from './calendarCar.css';
import cs from '../../components/commonStyle.css'

// Component
import Loader from '../../components/Loader';
import CarCalendar from '../../components/CarCalendar/CarCalendar';

// Graphql
import ManageListingsQuery from './manageListing.graphql';

class CalendarCar extends React.Component {
  static propTypes = {
    ManageListingsData: PropTypes.shape({
      loading: PropTypes.bool,
      ManageListings: PropTypes.array
    })
  };

  static defaultProps = {
    ManageListingsData: {
      loading: true,
      ManageListings: []
    }
  }

  render() {
    const { ManageListingsData, ManageListingsData: { ManageListings } } = this.props;

    return (
      <>
        {
          !ManageListings && <Loader type={"text"} />
        }
        {
          ManageListings && <CarCalendar data={ManageListingsData} />
        }
      </>
    );
  }
}

export default compose(
  withStyles(s, cs),
  graphql(ManageListingsQuery, {
    name: 'ManageListingsData',
    options: {
      ssr: false,
      fetchPolicy: 'network-only'
    }
  }),
)(CalendarCar);
