import fetch from '../../../core/fetch';
import messages from '../../../locale/messages';
import { SubmissionError } from 'redux-form';

const query0 = `query (
  $id: Int,
  $carType:String,
  $model:String,
  $transmission:String,
  $bedrooms:String,
  $year:String,
  $bedType:String,
  $beds:Int,
  $personCapacity:Int,
  $bathrooms:Float,
  $bathroomType:String,
  $country:String,
  $street:String,
  $buildingName:String,
  $city:String,
  $state:String,
  $zipcode:String,
  $lat: Float,
  $lng: Float,
  $isMapTouched: Boolean,
  $amenities: [Int],
  $safetyAmenities: [Int],
  $carFeatures: [Int],
  $bedTypes: String,
  $make: String,
  $odometer: String,
) {
    updateListing (
      id: $id,
      carType:$carType,
      model:$model,
      transmission: $transmission,
      bedrooms: $bedrooms,
      year: $year
      bedType: $bedType
      beds: $beds
      personCapacity: $personCapacity
      bathrooms: $bathrooms
      bathroomType: $bathroomType
      country: $country
      street: $street
      buildingName: $buildingName
      city: $city
      state: $state
      zipcode: $zipcode
      lat: $lat
      lng: $lng
      isMapTouched: $isMapTouched,
      amenities: $amenities,
      safetyAmenities: $safetyAmenities,
      carFeatures: $carFeatures,
      bedTypes: $bedTypes,
      make: $make,
      odometer: $odometer,
    ) {
      status
    }
  }`;

const query = `
  query ($listId:String!, $preview: Boolean) {
    UserListing (listId:$listId, preview: $preview) {
      id
      userId
      country
      street
      buildingName
      city
      state
      zipcode
      lat
      lng
      isMapTouched
      bedrooms
      beds
      personCapacity
      bathrooms
      transmission
      user {
        email
        userBanStatus
        profile{
          firstName
          lastName
          dateOfBirth
        }
      }
      userAmenities {
        amenitiesId
        listsettings{
          itemName
          settingsType {
            typeName
          }
        }
      }
      userSafetyAmenities {
        safetyAmenitiesId
        listsettings{
          itemName
          settingsType {
            typeName
          }
        }
      }
      userSpaces {
        spacesId
        listsettings{
          itemName
          settingsType {
            typeName
          }
        }
      }
      settingsData {
        id
        settingsId
        listsettings {
          id
          itemName
          settingsType {
            typeName
          }
        }
      }
      userBedsTypes{
        id
        listId
        bedCount
        bedType
      }
    }
  }
`;
const query1 = `
  query ($listId:String!, $currentStep: Int!) {
    ManageListingSteps (listId:$listId, currentStep: $currentStep) {
      id
      listId
      step1
      step2
      step3
      listing {
        id
        isReady
        isPublished
      }
      status
    }
  }
`;

const query2 = `query (
  $id: Int,
  $carRules: [Int],
  $bookingNoticeTime:String,
  $checkInStart:String,
  $checkInEnd:String,
  $maxDaysNotice:String,
  $minDay:Int,
  $maxDay:Int,
  $basePrice:Float,
  $delivery:Float,
  $currency:String,
  $weeklyDiscount:Float,
  $monthlyDiscount:Float,
  $blockedDates: [String],
  $bookingType: String!,
  $cancellationPolicy: Int,
  $securityDeposit: Float
) {
    updateListingStep3 (
      id: $id,
      carRules: $carRules,
      bookingNoticeTime:$bookingNoticeTime,
      checkInStart:$checkInStart,
      checkInEnd:$checkInEnd,
      maxDaysNotice:$maxDaysNotice,
      minDay:$minDay,
      maxDay:$maxDay,
      basePrice:$basePrice,
      delivery:$delivery,
      currency:$currency,
      weeklyDiscount:$weeklyDiscount,
      monthlyDiscount:$monthlyDiscount,
      blockedDates: $blockedDates,
      bookingType: $bookingType,
      cancellationPolicy: $cancellationPolicy,
      securityDeposit: $securityDeposit
    ) {
      status
    }
  }`;

  let mutation = `mutation UploadListPhotos ($listId:Int!, $name: String, $type: String) {
          CreateListPhotos (listId:$listId, name: $name, type: $type) {
            status
            photosCount
            id
          }
        }`;
  const queryDescription = `query (
        $id: Int,
        $description:String,
        $title:String,
        $coverPhoto: Int
      ) {
          updateListingStep2 (
            id: $id,
            description:$description,
            title:$title,
            coverPhoto: $coverPhoto
          ) {
            status
          }
        }`;

async function fetchApi(query , variables){
  const resp = await fetch('/graphql', {
    method: 'post',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json'
    },
    body: JSON.stringify({
      query: query,
      variables,
    }),
    credentials: 'include'
  });
  const { data } = await resp.json();
  return data;
}

async function ManageListingSteps(variables){
  const data = await fetchApi(query1 , variables);  
  return data;
}

const  updateListingDetails = (values) => async (dispatch) => {
    let namePhoto = values.photo, description = values.description , title = values.title;
    let listId = values.id;
    let weeklyDiscount = values.weeklyDiscount != '' ? values.weeklyDiscount : 0,
    monthlyDiscount = values.monthlyDiscount != '' ? values.monthlyDiscount : 0,
    delivery = values.delivery != '' ? values.delivery : 0,
    securityDeposit = values.securityDeposit ? Number(values.securityDeposit) : 0,
    id = values.id,
    carRules = values.carRules,
    bookingNoticeTime = values.bookingNoticeTime,
    checkInStart = values.checkInStart,
    checkInEnd = values.checkInEnd,
    maxDaysNotice = values.maxDaysNotice,
    minDay = values.minDay ? values.minDay : 0,
    maxDay = values.maxDay ? values.maxDay : 0,
    basePrice = values.basePrice,
    currency = values.currency,
    blockedDates = values.blockedDates,
    bookingType = values.bookingType,
    cancellationPolicy = values.cancellationPolicy,
    variables = Object.assign({}, {
      weeklyDiscount, monthlyDiscount,
      id, carRules, bookingNoticeTime,
      checkInStart, checkInEnd, maxDaysNotice,
      minDay, maxDay, basePrice,
      currency, blockedDates, bookingType,
      cancellationPolicy, delivery, securityDeposit
    });
    try {
      const data0 = await fetchApi(query0 , values);
      if (data0 && data0.updateListing.status === "success"){
        const data = await fetchApi(query , { listId, preview: true });
        if (data && data.UserListing) {
          const data1 = await ManageListingSteps({ listId, currentStep: 1 });
          if(data1 && data1.ManageListingSteps){
            const data2 = await ManageListingSteps({ listId, currentStep: 1 });
            if(data2 && data2.ManageListingSteps){
              const dataPhoto = await fetchApi(mutation , { listId, name: namePhoto, type: "image/jpeg"});
              if(dataPhoto && dataPhoto.CreateListPhotos && dataPhoto.CreateListPhotos.status === 'success'){ 
                const coverPhoto = dataPhoto.CreateListPhotos.id;
                const dataDescription =  await fetchApi(queryDescription , { id: listId, description,  title, coverPhoto});
                if(dataDescription && dataDescription.updateListingStep2){ 
                  const data3 = await ManageListingSteps({ listId, currentStep: 2 });
                  if(data3 && data3.ManageListingSteps){
                    const data4 = await fetchApi(query2 , variables);
                    if (data4 && data4.updateListingStep3) {
                      await ManageListingSteps({ listId, currentStep: 3 });
                    }
                  }   
                }
              }         
            }
          }
        }
      }
    } catch (error) {
      throw new SubmissionError({ _error: messages.somethingWentWrong });
    }
}
export default updateListingDetails;