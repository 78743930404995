import React from 'react';
import PropTypes from 'prop-types';
import Layout from '../layouts/Layout';
import Header from '../modules/Header';
import Body from '../modules/Body';
import Footer from '../modules/Footer';
import EmptySpace from '../modules/EmptySpace';
import { url } from '../../../config';
import { COMMON_TEXT_COLOR } from '../../../constants/index';
import { injectIntl, FormattedMessage} from 'react-intl';

class BanStatusServiceStatusUnBanned extends React.Component {

	static propTypes = {
		content: PropTypes.shape({
			userMail: PropTypes.string.isRequired,
			siteName: PropTypes.string.isRequired,
		}).isRequired
	};

	render() {

		const textStyle = {
			color: COMMON_TEXT_COLOR,
			backgroundColor: '#F7F7F7',
			fontFamily: 'Arial',
			fontSize: '16px',
			padding: '35px'
		};
		const { content: { userName, userMail, adminMail, logo, siteName } } = this.props;
		let mailTo = 'mailto:' + adminMail;

		return (
			<Layout>
				<Header 
					color="rgb(255, 90, 95)" 
					backgroundColor="#F7F7F7" 
					logo={logo}
					siteName={siteName}
				/>
				<Body textStyle={textStyle}>
					<div>
                        <FormattedMessage id="ban_status_service_banned_greeting" values={{ userName }} />
                    </div>
					<EmptySpace height={20} />
					<div>
                        <FormattedMessage id="ban_status_service_unbanned"/>
                    </div>
					<EmptySpace height={20} />
					<div>
                        <FormattedMessage id="ban_status_service_banned_contact_admin"
                            values={{ adminMail: <a href={mailTo}>{adminMail}</a> }}
                        />
					</div>
					<EmptySpace height={40} />
					<div>
                        <FormattedMessage id="confirmEmailThanksMessage" /><br/>
                        <FormattedMessage id="confirmEmailTeamMessage" values={{ siteName }} />
					</div>

				</Body>
				<Footer  siteName={siteName}/>
				<EmptySpace height={20} />
			</Layout>
		);
	}

}

export default injectIntl(BanStatusServiceStatusUnBanned);