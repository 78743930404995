exports = module.exports = require("../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".AdminFooter-footerContainer-3s0he {\n    position: fixed;\n    bottom: 0;\n    right: 0;\n    padding: 15px;\n    background: #f5f5f5;\n    width: calc(100% - 273px);\n    text-align: center;\n    border-top: 1px solid #dce0e0;\n    z-index: 1030;\n}\n@media screen and (max-width: 1199px) {\n    .AdminFooter-footerContainer-3s0he {\n        width: 100%;\n        margin-left: 0;\n    }\n}", ""]);

// exports
exports.locals = {
	"footerContainer": "AdminFooter-footerContainer-3s0he"
};